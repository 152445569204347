import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { TEAMS_QUERY_KEY } from "./teamsQueriesKeys";
import { TeamsServices } from "../../services/apis/Teams/TeamsServices";

export const useFetchTeamDetails = (teamId: string, params?: any) => {
  const queryResult = useQuery<any>({
    queryKey: [TEAMS_QUERY_KEY.FETCH_TEAM_DETAILS, teamId, params],
    queryFn: () => TeamsServices.getTeamDetails(teamId, params),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!teamId,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useUpdateTeam = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: TeamsServices.updateTeam,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [TEAMS_QUERY_KEY.FETCH_TEAM_DETAILS],
      });
    },
  });
};

export const useUploadTeamLogo = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: TeamsServices.uploadTeamLogo,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [TEAMS_QUERY_KEY.FETCH_TEAM_DETAILS],
      });
    },
  });
};
