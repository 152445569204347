import useAuthStore from "../../../stores/auth/auth";
import axios from "axios";

export const TeamsServices = {
  getTeamDetails: async (teamId: string, params?: any) => {
    const endPoint = `/api/game/team/${teamId}`;
    try {
      const team = await axios.get(endPoint, { params });
      return team?.data.message.data;
    } catch (error) {
      throw error;
    }
  },
  getAllLeagueTeams: async (leagueId: string) => {
    const endPoint = `/api/game/team/all/${leagueId}`;
    try {
      const teams = await axios.get(endPoint);
      return teams?.data;
    } catch (error) {
      throw error;
    }
  },
  updateTeam: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/game/team/${payload?.teamId}`;
    try {
      delete payload?.teamId;
      const updateTeam = await axios.patch(endPoint, payload, config);
      return updateTeam?.data;
    } catch (error) {
      throw error;
    }
  },
  uploadTeamLogo: async (payload: any) => {
    const { loginData } = useAuthStore.getState();
    const config = {
      headers: { Authorization: `Bearer ${loginData?.access_token}` },
    };
    const endPoint = `/api/game/team/logo/${payload?.teamId}`;
    try {
      delete payload?.teamId;
      const updateTeam = await axios.patch(endPoint, payload, config);
      return updateTeam?.data;
    } catch (error) {
      throw error;
    }
  },
};
