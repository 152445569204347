import moment from "moment";
import useFilter from "../../../../../hooks/useFilter";
import { useFetchUserTransactions } from "../../../../../queries/paymentsQueries/paymentsQueries";
import useAuthStore from "../../../../../stores/auth/auth";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useFetchUserDetails } from "../../../../../queries/userQueries/userQueries";

const DateRender = ({ ...data }) => {
  return (
    <div className={`flex flex-row items-center`}>
      <div className="flex flex-col font-gilroyMedium">
        {moment(data?.createdAt).format("lll")}
      </div>
    </div>
  );
};

const TypeRender = ({ ...data }) => {
  return (
    <div className={`flex flex-row items-center`}>
      <div className="flex flex-col font-gilroyMedium capitalize">
        {data?.type || "-"}
      </div>
    </div>
  );
};

const AmountRender = ({ ...data }) => {
  return (
    <div className={`flex flex-row items-center`}>
      <p
        className={clsx(
          "text-text-red font-gilroyMedium",
          (data?.type === "deposit" ||
            data?.type?.toLocaleLowerCase()?.includes("win") ||
            data?.type === "deposit from admin" ||
            data?.type === "transfer from admin" ||
            data?.type === "credit from admin") &&
            "!text-text-green"
        )}
      >
        {data?.type === "deposit" ||
        data?.type?.toLocaleLowerCase()?.includes("win") ||
        data?.type === "deposit from admin" ||
        data?.type === "transfer from admin" ||
        data?.type === "credit from admin"
          ? "+"
          : "-"}
        ${data?.amount}
      </p>
    </div>
  );
};

const StatusRender = ({ ...data }) => {
  return (
    <div className={`flex items-center justify-between`}>
      <div
        className={clsx(
          "text-xs flex justify-center items-center font-gilroyBold px-3 py-1 rounded-[16px] bg-gradient-to-br capitalize text-nowrap",
          data?.status === "registration" &&
            "from-elements-chipGradient-1 to-elements-chipGradient-2",
          data?.status === "inProgress" &&
            "from-red-bgGradient2-1 to-red-bgGradient2-2",
          data?.status === "completed" && "bg-white text-screenBgGrey",
          data?.status === "archived" && "bg-white text-screenBgGrey"
        )}
      >
        {data?.status || ""}
      </div>
    </div>
  );
};

const ActionRender = () => {
  return <></>;
};

const useTransactionHistoryHook = () => {
  const { userData } = useAuthStore.getState();
  const { filter, onChangeFilter } = useFilter({});
  const { data: user } = useFetchUserDetails(userData?.id);
  const { data, isFetching } = useFetchUserTransactions(userData?.id, {
    page: filter?.page,
    limit: filter?.limit,
  });
  const [type, setType] = useState("All");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [transactions, setTransactions] = useState(data ?? []);

  const onFilterValues = () => {
    const filteredTransactions = data
      ?.filter((item: any) => {
        if (type !== "All") {
          return item.type === type?.toLocaleLowerCase();
        } else return item.type;
      })
      .filter((item: any) => {
        if (fromDate !== null && toDate !== null) {
          return (
            new Date(item?.createdAt) >= new Date(fromDate) &&
            new Date(item?.createdAt) <= new Date(toDate)
          );
        } else return item;
      });

    setTransactions(filteredTransactions);
  };

  useEffect(() => {
    onFilterValues();
  }, [type, data, fromDate, toDate]);

  const filters = {
    type: ["All", "Transfer", "Deposit", "Withdrawal"],
  };

  const columns = [
    {
      title: "Date & Time",
      dataIndex: "date",
      render: (_: string, record: any) => {
        return <DateRender {...record} />;
      },
      sort: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (_: string, record: any) => {
        return <TypeRender {...record} />;
      },
      sort: true,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (_: string, record: any) => {
        return <AmountRender {...record} />;
      },
      sort: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_: string, record: any) => {
        return <StatusRender {...record} />;
      },
      sort: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_: string, record: any) => {
        return <ActionRender {...record} />;
      },
      sort: true,
    },
  ];

  return {
    columns,
    filter,
    onChangeFilter,
    transactions,
    isFetching,
    filters,
    type,
    setType,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    user,
  };
};

export default useTransactionHistoryHook;
