import { useNavigate, useParams } from "react-router-dom";
import { useFetchTeamDetails } from "../../../../queries/teamsQueries/teamsQueries";
import league_logo from "../../../../assets/images/ball-logo.png";
import demo_team_logo from "../../../../assets/images/demoTeamLogo.png";
import PlayerDetailsModal from "../components/PlayerDetails";
import { Button } from "../../../../components";
import {
  useFetchTeamPlayers,
  useFetchTeamPlayersPRJ,
  useRemovePlayer,
} from "../../../../queries/playerQueries/playerQueries";
import { toast } from "react-toastify";
import useAuthStore from "../../../../stores/auth/auth";
import { isValidImg, getCurrentWeek } from "../../../../utils/helpers";
import {
  useFetchCurrentWeek,
  useFetchLeague,
} from "../../../../queries/leaguesQueries/leaguesQueries";
import useLoadPlayers from "../../../../pages/private/Draft/hooks/useLoadPlayers";
import DropInfoModal from "../components/DropInfoModal";
import { useState } from "react";
import { Oval } from "react-loader-spinner";
import clsx from "clsx";
import { useFetchTeamUserDetails } from "../../../../queries/userQueries/userQueries";
import RemovePlayerModal from "../components/RemovePlayerByAdmin";

const NameRender = ({ ...data }) => {
  const player = data?.teamData?.player?.find(
    (item: any) => item?.playerId === data?.player?.id
  );
  return (
    <PlayerDetailsModal
      teamId={data?.teamId}
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <div className={`flex items-center min-w-[120px]`}>
        <img
          src={isValidImg(player?.avatar || data?.avatar) || demo_team_logo}
          alt="League logo"
          className="w-[32px] h-[32px] rounded-[20px]"
        />
        <div className="flex w-full flex-col ml-2 font-gilroyMedium">
          {data?.player?.firstName} {data?.player?.lastName} {data?.name}
        </div>
      </div>
    </PlayerDetailsModal>
  );
};

const PositionRender = ({ ...data }) => {
  return (
    <PlayerDetailsModal
      teamId={data?.teamId}
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <div className="font-gilroyMedium whitespace-nowrap">
        {data?.player?.position || data?.position || ""}
      </div>
    </PlayerDetailsModal>
  );
};

const TeamRender = ({ ...data }) => {
  return (
    <PlayerDetailsModal
      teamId={data?.teamId}
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <div className={`flex items-center`}>
        <img
          src={
            data?.officialImageSrc
              ? isValidImg(data?.officialImageSrc)
                ? data?.officialImageSrc
                : league_logo
              : league_logo
          }
          alt="League logo"
          className="w-[16px] h-[16px] rounded-[20px]"
        />
        <div className="flex flex-col ml-2 font-gilroyMedium whitespace-nowrap">
          {data?.team?.abbreviation || data?.nflTeam || "-"}
        </div>
      </div>
    </PlayerDetailsModal>
  );
};

const PointsRender = ({ ...data }) => {
  return (
    <PlayerDetailsModal
      teamId={data?.teamId}
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <div className="font-gilroyMedium whitespace-nowrap">
        {data?.fantasyPointsThisWeek?.toFixed(2) ?? "-"}
        {/* @TODO Discuss with Yurii: Why would we calculate fantasy points on the frontend? */}
        {/*{data?.stats && data?.isRealStats*/}
        {/*  ? data?.isPPR*/}
        {/*    ? updatePlayerScore(data?.stats)?.toFixed(2)*/}
        {/*    : updatePlayerScoreNonPpr(data?.stats)?.toFixed(2)*/}
        {/*  : data?.score ?? "-"}*/}
      </div>
    </PlayerDetailsModal>
  );
};
const PRJPointsRender = ({ ...data }) => {
  return (
    <PlayerDetailsModal
      teamId={data?.teamId}
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <div
        className={clsx(
          "font-gilroyMedium whitespace-nowrap",
          "!text-text-greyDark"
        )}
      >
        {data?.projectedFantasyPointsThisWeek?.toFixed(2) ?? "0"}
        {/* @TODO Discuss with Yurii: Why would we calculate fantasy points on the frontend? */}
        {/*{data?.projectedStats && !data?.isPastWeek*/}
        {/*  ? data?.isPPR*/}
        {/*    ? updatePlayerScore(data?.projectedStats)?.toFixed(2)*/}
        {/*    : updatePlayerScoreNonPpr(data?.projectedStats)?.toFixed(2)*/}
        {/*  : data?.projectedPoints ?? "-"}*/}
      </div>
    </PlayerDetailsModal>
  );
};
const PassATTRender = ({ ...data }) => {
  const isRealStats = data?.stats;
  return (
    <PlayerDetailsModal
      teamId={data?.teamId}
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <p
        className={clsx(
          "font-gilroyMedium whitespace-nowrap",
          !isRealStats && "!text-text-greyDark"
        )}
      >
        {isRealStats
          ? data?.stats?.passing?.passCompletions
          : data?.projectedStats?.passing?.passCompletions ?? "0"}
        /
        {isRealStats
          ? data?.stats?.passing?.passAttempts
          : data?.projectedStats?.passing?.passAttempts ?? "0"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.passingCompletions*/}
        {/*  : (isRealStats && (data?.isPastWeek || data?.isCurrentWeek)*/}
        {/*      ? data?.stats?.passing?.passCompletions*/}
        {/*      : !isRealStats && !data?.isPastWeek*/}
        {/*      ? data?.projectedStats?.passing?.passCompletions*/}
        {/*      : "-") ?? "-"}*/}
        {/*/*/}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.passingAttempts*/}
        {/*  : (isRealStats && (data?.isPastWeek || data?.isCurrentWeek)*/}
        {/*      ? data?.stats?.passing?.passAttempts*/}
        {/*      : !isRealStats && !data?.isPastWeek*/}
        {/*      ? data?.projectedStats?.passing?.passAttempts*/}
        {/*      : "-") ?? "-"}*/}
      </p>
    </PlayerDetailsModal>
  );
};
const PassYDSRender = ({ ...data }) => {
  const isRealStats = data?.stats;
  return (
    <PlayerDetailsModal
      teamId={data?.teamId}
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <p
        className={clsx(
          "font-gilroyMedium whitespace-nowrap",
          !isRealStats && "!text-text-greyDark"
        )}
      >
        {isRealStats
          ? data?.stats?.passing?.passYards
          : data?.projectedStats?.passing?.passYards ?? "0"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.passingYards*/}
        {/*  : (isRealStats && (data?.isPastWeek || data?.isCurrentWeek)*/}
        {/*      ? data?.stats?.passing?.passYards*/}
        {/*      : !isRealStats && !data?.isPastWeek*/}
        {/*      ? data?.projectedStats?.passing?.passYards*/}
        {/*      : "-") ?? "-"}*/}
      </p>
    </PlayerDetailsModal>
  );
};
const PassTDRender = ({ ...data }) => {
  const isRealStats = data?.stats;
  return (
    <PlayerDetailsModal
      teamId={data?.teamId}
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <p
        className={clsx(
          "font-gilroyMedium whitespace-nowrap",
          !isRealStats && "!text-text-greyDark"
        )}
      >
        {isRealStats
          ? data?.stats?.passing?.passTD
          : data?.projectedStats?.passing?.passTD ?? "0"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.passingTds*/}
        {/*  : (isRealStats && (data?.isPastWeek || data?.isCurrentWeek)*/}
        {/*      ? data?.stats?.passing?.passTD*/}
        {/*      : !isRealStats && !data?.isPastWeek*/}
        {/*      ? data?.projectedStats?.passing?.passTD*/}
        {/*      : "-") ?? "-"}*/}
      </p>
    </PlayerDetailsModal>
  );
};
const PassINTRender = ({ ...data }) => {
  const isRealStats = data?.stats;

  return (
    <PlayerDetailsModal
      teamId={data?.teamId}
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <p
        className={clsx(
          "font-gilroyMedium whitespace-nowrap",
          !isRealStats && "!text-text-greyDark"
        )}
      >
        {isRealStats
          ? data?.stats?.passing?.passInt
          : data?.projectedStats?.passing?.passInt ?? "0"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.passingInterceptions*/}
        {/*  : (isRealStats && (data?.isPastWeek || data?.isCurrentWeek)*/}
        {/*      ? data?.stats?.passing?.passInt*/}
        {/*      : !isRealStats && !data?.isPastWeek*/}
        {/*      ? data?.projectedStats?.passing?.passInt*/}
        {/*      : "-") ?? "-"}*/}
      </p>
    </PlayerDetailsModal>
  );
};
const RushCARRender = ({ ...data }) => {
  const isRealStats = data?.stats;

  return (
    <PlayerDetailsModal
      teamId={data?.teamId}
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <p
        className={clsx(
          "font-gilroyMedium whitespace-nowrap",
          !isRealStats && "!text-text-greyDark"
        )}
      >
        {isRealStats
          ? data?.stats?.rushing?.rushAttempts
          : data?.projectedStats?.rushing?.rushAttempts ?? "0"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.rushingAttempts*/}
        {/*  : (isRealStats && (data?.isPastWeek || data?.isCurrentWeek)*/}
        {/*      ? data?.stats?.rushing?.rushAttempts*/}
        {/*      : !isRealStats && !data?.isPastWeek*/}
        {/*      ? data?.projectedStats?.rushing?.rushAttempts*/}
        {/*      : "-") ?? "-"}*/}
      </p>
    </PlayerDetailsModal>
  );
};
const RushYDSRender = ({ ...data }) => {
  const isRealStats = data?.stats;

  return (
    <PlayerDetailsModal
      teamId={data?.teamId}
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <p
        className={clsx(
          "font-gilroyMedium whitespace-nowrap",
          !isRealStats && "!text-text-greyDark"
        )}
      >
        {isRealStats
          ? data?.stats?.rushing?.rushYards
          : data?.projectedStats?.rushing?.rushYards ?? "0"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.rushingYards*/}
        {/*  : (isRealStats && (data?.isPastWeek || data?.isCurrentWeek)*/}
        {/*      ? data?.stats?.rushing?.rushYards*/}
        {/*      : !isRealStats && !data?.isPastWeek*/}
        {/*      ? data?.projectedStats?.rushing?.rushYards*/}
        {/*      : "-") ?? "-"}*/}
      </p>
    </PlayerDetailsModal>
  );
};
const RushTDRender = ({ ...data }) => {
  const isRealStats = data?.stats;
  return (
    <PlayerDetailsModal
      teamId={data?.teamId}
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <p
        className={clsx(
          "font-gilroyMedium whitespace-nowrap",
          !isRealStats && "!text-text-greyDark"
        )}
      >
        {isRealStats
          ? data?.stats?.rushing?.rushTD
          : data?.projectedStats?.rushing?.rushTD ?? "0"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.rushingTds*/}
        {/*  : (isRealStats && (data?.isPastWeek || data?.isCurrentWeek)*/}
        {/*      ? data?.stats?.rushing?.rushTD*/}
        {/*      : !isRealStats && !data?.isPastWeek*/}
        {/*      ? data?.projectedStats?.rushing?.rushTD*/}
        {/*      : "-") ?? "-"}*/}
      </p>
    </PlayerDetailsModal>
  );
};
const RecRender = ({ ...data }) => {
  const isRealStats = data?.stats;
  return (
    <PlayerDetailsModal
      teamId={data?.teamId}
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <p
        className={clsx(
          "font-gilroyMedium whitespace-nowrap",
          !isRealStats && "!text-text-greyDark"
        )}
      >
        {isRealStats
          ? data?.stats?.receiving?.receptions
          : data?.projectedStats?.receiving?.receptions ?? "0"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.receivingReceptions*/}
        {/*  : (isRealStats && (data?.isPastWeek || data?.isCurrentWeek)*/}
        {/*      ? data?.stats?.receiving?.receptions*/}
        {/*      : !isRealStats && !data?.isPastWeek*/}
        {/*      ? data?.projectedStats?.receiving?.receptions*/}
        {/*      : "-") ?? "-"}*/}
      </p>
    </PlayerDetailsModal>
  );
};
const RecYDSRender = ({ ...data }) => {
  const isRealStats = data?.stats;
  return (
    <PlayerDetailsModal
      teamId={data?.teamId}
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <p
        className={clsx(
          "font-gilroyMedium whitespace-nowrap",
          !isRealStats && "!text-text-greyDark"
        )}
      >
        {isRealStats
          ? data?.stats?.receiving?.recYards
          : data?.projectedStats?.receiving?.recYards ?? "0"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.receivingYards*/}
        {/*  : (isRealStats && (data?.isPastWeek || data?.isCurrentWeek)*/}
        {/*      ? data?.stats?.receiving?.recYards*/}
        {/*      : !isRealStats && !data?.isPastWeek*/}
        {/*      ? data?.projectedStats?.receiving?.recYards*/}
        {/*      : "-") ?? "-"}*/}
      </p>
    </PlayerDetailsModal>
  );
};
const RecTDRender = ({ ...data }) => {
  const isRealStats = data?.stats;
  return (
    <PlayerDetailsModal
      teamId={data?.teamId}
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <p
        className={clsx(
          "font-gilroyMedium whitespace-nowrap",
          !isRealStats && "!text-text-greyDark"
        )}
      >
        {isRealStats
          ? data?.stats?.receiving?.recTD
          : data?.projectedStats?.receiving?.recTD ?? "0"}
        {/*{data?.isCurrentWeek*/}
        {/*  ? data?.receivingTds*/}
        {/*  : (isRealStats && (data?.isPastWeek || data?.isCurrentWeek)*/}
        {/*      ? data?.stats?.receiving?.recTD*/}
        {/*      : !isRealStats && !data?.isPastWeek*/}
        {/*      ? data?.projectedStats?.receiving?.recTD*/}
        {/*      : "-") ?? "-"}*/}
      </p>
    </PlayerDetailsModal>
  );
};

const StatusRender = ({ ...data }) => {
  const player = data?.teamData?.player?.find(
    (item: any) => item?.playerId === data?.player?.id
  );

  return (
    <PlayerDetailsModal
      teamId={data?.teamId}
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <div className="font-gilroyMedium lowercase">
        {player?.status || data?.status || "-"}
      </div>
    </PlayerDetailsModal>
  );
};

const ActionsRender = ({ ...data }) => {
  const limitedPosition = data?.teamData?.player?.filter(
    (item: any) => item?.position === data?.player?.primaryPosition
  );
  return (
    <>
      {data?.isMyTeam && data?.draft?.status === "completed" ? (
        limitedPosition?.length === 1 ? (
          <DropInfoModal />
        ) : (
          <Button
            className="h-[41px] w-[65px] rounded-[8px]"
            leftIcon={
              data?.isLoading ? (
                <Oval
                  visible={data?.isLoading}
                  height="20"
                  width="20"
                  color="#000000"
                  secondaryColor="#ffffff"
                />
              ) : null
            }
            disabled={data?.isLoading || !data?.canDropPlayer}
            onClick={() => data?.dropPlayer(data?.player?.id || data?.playerId)}
            mode="secondary"
          >
            Drop
          </Button>
        )
      ) : data?.isAdmin ? (
        <RemovePlayerModal
          teamId={data?.teamId}
          playerId={data?.player?.id || data?.playerId}
          week={data?.currentWeek}
        />
      ) : null}
    </>
  );
};

const OpponentRender = ({ ...data }) => {
  let opponent = "";
  let isHomeGame = "";
  if (data?.game?.awayTeamAbbreviation) {
    opponent =
      data?.game?.awayTeamAbbreviation === data?.team?.abbreviation
        ? data?.game?.homeTeamAbbreviation
        : data?.game?.awayTeamAbbreviation;
    isHomeGame =
      data?.game?.homeTeamAbbreviation !== data?.team?.abbreviation ? "@" : "";
  } else {
    const player = data?.uniquePlayers?.find(
      (item: any) => item?.player?.id === data?.playerId
    );
    opponent =
      player?.game?.awayTeamAbbreviation === player?.team?.abbreviation
        ? player?.game?.homeTeamAbbreviation
        : player?.game?.awayTeamAbbreviation;
    isHomeGame =
      player?.game?.homeTeamAbbreviation !== player?.team?.abbreviation
        ? "@"
        : "";
  }

  return (
    <PlayerDetailsModal
      teamId={data?.teamId}
      id={data?.player?.id || data?.playerId}
      leagueId={data?.leagueId}
    >
      <div className="font-gilroyMedium whitespace-nowrap ml-1">
        {isHomeGame}
        {opponent || "BYE"}
      </div>
    </PlayerDetailsModal>
  );
};

const useTeamHook = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { userData } = useAuthStore.getState();
  const { data: currentWeek } = useFetchCurrentWeek();
  const [week, setWeek] = useState<any>(getCurrentWeek());
  const {
    data: team,
    isFetching,
    refetch,
  } = useFetchTeamDetails(id!, { week: week });
  const { data: user } = useFetchTeamUserDetails(team?.userId);
  const { mutateAsync } = useRemovePlayer();
  const { data: league } = useFetchLeague(team?.leagueId!);
  const {} = useLoadPlayers(team?.leagueId!);

  const isCurrentWeek = !week || week === currentWeek;
  const isPastWeek = week < currentWeek;

  const weekSlots = currentWeek
    ? [...Array(Math.ceil(currentWeek)).keys()]
    : [];
  const prjSlots = currentWeek
    ? [...Array(18 - Math.ceil(currentWeek)).keys()]
    : [];

  const { data: players, isFetching: isFetchingPlayers } = useFetchTeamPlayers(
    {
      names: team?.player?.map((item: any) => {
        const firstName = item?.name?.split(" ")?.[0];
        const lastName = item?.name
          ?.split(" ")
          ?.splice(1, item?.name?.split(" ")?.length - 1)
          ?.join(" ");
        return { firstName: firstName, lastName: lastName };
      }),
    },
    week ? week : currentWeek,
    week > currentWeek
  );
  const { data: playersPRJ, isFetching: isFetchingPlayersPRJ } =
    useFetchTeamPlayersPRJ(
      {
        names: team?.player?.map((item: any) => {
          const firstName = item?.name?.split(" ")?.[0];
          const lastName = item?.name
            ?.split(" ")
            ?.splice(1, item?.name?.split(" ")?.length - 1)
            ?.join(" ");
          return { firstName: firstName, lastName: lastName };
        }),
      },
      week ? week : currentWeek,
      true
      // !week || week <= currentWeek
    );

  const playersAll = players ? Object.values(players) : [];
  const playersAllPRJ = playersPRJ ? Object.values(playersPRJ) : [];
  const combinedPlayers = [...playersAllPRJ, ...playersAll];

  const playersWithStats = Array.from(
    new Map(
      combinedPlayers?.map((item: any) => [item?.player?.id, item])
    )?.values()
  );

  const existingIds = new Set(
    playersWithStats?.map((item) => item?.player?.id)
  );
  const missingPlayers =
    team?.player?.filter(
      (player: any) => !existingIds?.has(player?.playerId)
    ) || [];
  const uniquePlayers = [...playersWithStats, ...missingPlayers];

  const orderPlayers = (players: any) => {
    const orderedPlayers = [];
    const positionPriority = ["QB", "RB", "WR", "TE"];
    const positionMap: any = { QB: null, RB: null, WR: null, TE: null };

    positionPriority?.forEach((pos) => {
      const player = players?.find(
        (item: any) => item?.player?.position === pos || item?.position === pos
      );
      if (player) {
        orderedPlayers?.push(player);
        positionMap[pos] = player;
        players = players?.filter(
          (item: any) =>
            item?.player?.id !== player?.player?.id ||
            item?.playerId !== player?.playerId
        );
      }
    });

    orderedPlayers.push(...players);
    return orderedPlayers;
  };

  const dropPlayer = async (playerId: string) => {
    try {
      await mutateAsync({ teamId: id, playerId: playerId });
      refetch();
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    }
  };
  const isMyTeam =
    userData?.id === team?.userId && team?.status === "Confirmed";
  const isAdmin = userData?.role === "admin";

  const columns = [
    {
      title: "Player Name",
      dataIndex: "name",
      render: (_: string, record: any) => {
        return (
          <NameRender
            isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            teamData={team}
            teamId={id}
          />
        );
      },
      sort: true,
    },
    {
      title: "Pos",
      dataIndex: "position",
      render: (_: string, record: any) => {
        return (
          <PositionRender
            isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            teamId={id}
          />
        );
      },
      sort: true,
    },
    {
      title: "Team",
      dataIndex: "team",
      render: (_: string, record: any) => {
        return (
          <TeamRender
            isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            teamId={id}
            uniquePlayers={playersAllPRJ}
          />
        );
      },
      sort: true,
    },
    {
      title: "OPP",
      dataIndex: "opp",
      render: (_: string, record: any) => {
        return (
          <OpponentRender
            isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            teamId={id}
            uniquePlayers={playersAllPRJ}
          />
        );
      },
      sort: true,
    },
    {
      title: "Fantasy Points",
      dataIndex: "points",
      render: (_: string, record: any) => {
        return (
          <PointsRender
            isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            isPPR={league?.league?.receivingOption === "PPR"}
            isRealStats={week <= currentWeek}
            teamId={id}
          />
        );
      },
      sort: true,
    },
    {
      title: "Proj",
      dataIndex: "prj",
      render: (_: string, record: any) => {
        return (
          <PRJPointsRender
            isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            isPPR={league?.league?.receivingOption === "PPR"}
            isRealStats={week <= currentWeek}
            isCurrentWeek={isCurrentWeek}
            isPastWeek={isPastWeek}
            teamId={id}
            isProjected={!isPastWeek && !isCurrentWeek && !week <= currentWeek}
          />
        );
      },
      sort: true,
    },
    {
      title: (
        <p className="text-base font-gilroyMedium text-text-grey">CMP/ATT</p>
      ),
      dataIndex: "passATT",
      render: (_: string, record: any) => {
        return (
          <PassATTRender
            isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            isRealStats={week <= currentWeek}
            isCurrentWeek={isCurrentWeek}
            isPastWeek={isPastWeek}
            teamId={id}
            isProjected={!isPastWeek && !isCurrentWeek && !week <= currentWeek}
          />
        );
      },
      sort: true,
    },
    {
      title: <p className="text-base font-gilroyMedium text-text-grey">YDS</p>,
      dataIndex: "passYDS",
      render: (_: string, record: any) => {
        return (
          <PassYDSRender
            isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            isRealStats={week <= currentWeek}
            isCurrentWeek={isCurrentWeek}
            isPastWeek={isPastWeek}
            teamId={id}
            isProjected={!isPastWeek && !isCurrentWeek && !week <= currentWeek}
          />
        );
      },
      sort: true,
    },
    {
      title: (
        <div className="relative">
          <p className="text-[12px] font-gilroyBold text-text-grey top-[-12px] -translate-x-[100%] absolute">
            Passing
          </p>
          <p className="text-base font-gilroyMedium text-text-grey">TDs</p>
        </div>
      ),
      dataIndex: "passTD",
      render: (_: string, record: any) => {
        return (
          <PassTDRender
            isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            isRealStats={week <= currentWeek}
            isCurrentWeek={isCurrentWeek}
            isPastWeek={isPastWeek}
            teamId={id}
            isProjected={!isPastWeek && !isCurrentWeek && !week <= currentWeek}
          />
        );
      },
      sort: true,
    },
    {
      title: (
        <p className="text-base font-gilroyMedium text-text-grey mr-4">INTs</p>
      ),
      dataIndex: "passINT",
      render: (_: string, record: any) => {
        return (
          <PassINTRender
            isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            isRealStats={week <= currentWeek}
            isCurrentWeek={isCurrentWeek}
            isPastWeek={isPastWeek}
            teamId={id}
            isProjected={!isPastWeek && !isCurrentWeek && !week <= currentWeek}
          />
        );
      },
      sort: true,
    },
    {
      title: <p className="text-base font-gilroyMedium text-text-grey">CAR</p>,
      dataIndex: "rushCAR",
      render: (_: string, record: any) => {
        return (
          <RushCARRender
            isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            isRealStats={week <= currentWeek}
            isCurrentWeek={isCurrentWeek}
            isPastWeek={isPastWeek}
            teamId={id}
            isProjected={!isPastWeek && !isCurrentWeek && !week <= currentWeek}
          />
        );
      },
      sort: true,
    },
    {
      title: <p className="text-base font-gilroyMedium text-text-grey">YDS</p>,
      dataIndex: "rushYDS",
      render: (_: string, record: any) => {
        return (
          <RushYDSRender
            isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            isRealStats={week <= currentWeek}
            isCurrentWeek={isCurrentWeek}
            isPastWeek={isPastWeek}
            teamId={id}
            isProjected={!isPastWeek && !isCurrentWeek && !week <= currentWeek}
          />
        );
      },
      sort: true,
    },
    {
      title: (
        <div className="relative mr-4">
          <p className="text-[12px] font-gilroyBold text-text-grey top-[-12px] -translate-x-[110%] absolute">
            Rushing
          </p>
          <p className="text-base font-gilroyMedium text-text-grey">TDs</p>
        </div>
      ),
      dataIndex: "rushTD",
      render: (_: string, record: any) => {
        return (
          <RushTDRender
            isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            isRealStats={week <= currentWeek}
            isCurrentWeek={isCurrentWeek}
            isPastWeek={isPastWeek}
            teamId={id}
            isProjected={!isPastWeek && !isCurrentWeek && !week <= currentWeek}
          />
        );
      },
      sort: true,
    },
    {
      title: <p className="text-base font-gilroyMedium text-text-grey">Rec</p>,
      dataIndex: "rec",
      render: (_: string, record: any) => {
        return (
          <RecRender
            isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            isRealStats={week <= currentWeek}
            isCurrentWeek={isCurrentWeek}
            isPastWeek={isPastWeek}
            teamId={id}
            isProjected={!isPastWeek && !isCurrentWeek && !week <= currentWeek}
          />
        );
      },
      sort: true,
    },
    {
      title: (
        <div className="relative">
          <p className="text-[12px] font-gilroyBold text-text-grey top-[-12px] -translate-x-[20%] absolute">
            Receiving
          </p>
          <p className="text-base font-gilroyMedium text-text-grey">YDS</p>
        </div>
      ),
      dataIndex: "recYDS",
      render: (_: string, record: any) => {
        return (
          <RecYDSRender
            isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            isRealStats={week <= currentWeek}
            isCurrentWeek={isCurrentWeek}
            isPastWeek={isPastWeek}
            teamId={id}
            isProjected={!isPastWeek && !isCurrentWeek && !week <= currentWeek}
          />
        );
      },
      sort: true,
    },
    {
      title: <p className="text-base font-gilroyMedium text-text-grey">TDs</p>,
      dataIndex: "recTD",
      render: (_: string, record: any) => {
        return (
          <RecTDRender
            isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            isRealStats={week <= currentWeek}
            isCurrentWeek={isCurrentWeek}
            isPastWeek={isPastWeek}
            teamId={id}
            isProjected={!isPastWeek && !isCurrentWeek && !week <= currentWeek}
          />
        );
      },
      sort: true,
    },
    {
      title: "Inj Status",
      dataIndex: "status",
      render: (_: string, record: any) => {
        return (
          <StatusRender
            isMyTeam={isMyTeam}
            {...record}
            leagueId={team?.leagueId}
            teamData={team}
            teamId={id}
          />
        );
      },
      sort: true,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_: string, record: any) => {
        return (
          <ActionsRender
            teamData={team}
            isMyTeam={isMyTeam}
            dropPlayer={dropPlayer}
            draft={league?.league?.draft?.[0]}
            isLoading={isFetching || isFetchingPlayers}
            {...record}
            leagueId={team?.leagueId}
            teamId={id}
            isAdmin={isAdmin}
            canDropPlayer={!week || week >= currentWeek}
            currentWeek={currentWeek}
          />
        );
      },
      sort: true,
    },
  ];

  return {
    isFetching,
    team,
    userData,
    columns,
    isMyTeam,
    isAdmin,
    league,
    navigate,
    week,
    setWeek,
    weekSlots,
    players,
    isFetchingPlayers,
    refetch,
    prjSlots,
    currentWeek,
    uniquePlayers,
    isFetchingPlayersPRJ,
    user,
    orderPlayers,
  };
};

export default useTeamHook;
