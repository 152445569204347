import { useEffect, useState } from "react";
import clsx from "clsx";
import league_logo from "../../../../../assets/images/ball-logo.png";
import { ReactComponent as Info } from "../../../../../assets/icons/info-red-icon.svg";
import useFilter from "../../../../../hooks/useFilter";
import {
  useFetchLeaguesList,
  useFetchMyLeaguesList,
} from "../../../../../queries/leaguesQueries/leaguesQueries";
import { useNavigate } from "react-router";
import useAuthStore from "../../../../../stores/auth/auth";
import LeagueRegistrationModal from "../../../League/components/LeagueRegistration";
import { Popup } from "../../../../../components/Popup/Popup";
import {
  formatDateTimeInTimeZone,
  isValidImg,
  numberWithCommas,
} from "../../../../../utils/helpers";

const NameRender = ({ ...data }) => {
  return (
    <div className={`flex flex-row items-center`}>
      <img
        className="w-[40px] h-[40px] rounded-[20px]"
        src={isValidImg(data.logoUrl) || league_logo}
        alt="League logo"
      />
      <div className="flex flex-col ml-6 text-base">{data?.name || ""}</div>
    </div>
  );
};

const StatusRender = ({ ...data }) => {
  return (
    <div className={`flex items-center justify-between`}>
      <div
        className={clsx(
          "text-base px-4 py-1 rounded-[16px] bg-gradient-to-br capitalize text-nowrap",
          data?.status === "registration" &&
            "from-elements-chipGradient-1 to-elements-chipGradient-2",
          data?.status === "inProgress" &&
            "from-red-bgGradient2-1 to-red-bgGradient2-2",
          data?.status === "completed" && "bg-white text-screenBgGrey",
          data?.status === "archived" && "bg-white text-screenBgGrey"
        )}
      >
        {data?.status || ""}
      </div>
    </div>
  );
};

const TeamsRender = ({ ...data }) => {
  return (
    <div className={`flex flex-col`}>
      <p>
        {data?.team
          ? data?.team?.length + "/" + data?.numberOfTeams
          : data?.numberOfTeams + " Teams"}
      </p>
      <p className="text-sm text-text-subGrey">
        {data?.teamsEliminatedPerWeek + " Eliminated Weekly"}
      </p>
    </div>
  );
};

const EliminatedRender = ({ ...data }) => {
  return (
    <div className={`flex flex-col`}>
      <p>{data?.totalEliminatedTeams ?? 0}</p>
      <p className="text-sm text-text-subGrey">Eliminated</p>
    </div>
  );
};

const EntryFeeRender = ({ ...data }) => {
  return (
    <div className={`flex flex-col`}>
      <p>${data?.entryFee}</p>
      <p className="text-sm text-text-subGrey">Entry Fee</p>
    </div>
  );
};

const PrizeRender = ({ ...data }) => {
  const prize =
    Number(data?.prizeFundFirstPlace) + Number(data?.prizeFundSecondPlace);
  return (
    <div className="flex items-center gap-2">
      <div className={`flex flex-col`}>
        <p>${numberWithCommas(prize?.toFixed(2))}</p>
        <p className="text-sm text-text-subGrey">Prize Fund</p>
      </div>
      <Popup
        lastItem={data?.lastLeague?.id === data?.id}
        icon={<Info />}
        data={
          <p className="text-base text-left whitespace-nowrap">
            1st Place Prize $
            {numberWithCommas(Number(data?.prizeFundFirstPlace)?.toFixed(2))}{" "}
            <br />
            2nd Place Prize $
            {numberWithCommas(Number(data?.prizeFundSecondPlace)?.toFixed(2))}
          </p>
        }
      />
    </div>
  );
};
const ViewLeagueRender = ({ ...data }) => {
  const { userData } = useAuthStore.getState();
  return (
    <div
      onClick={() =>
        data?.navigate(
          userData?.id
            ? `/leagues/league/${data?.id}`
            : `/allleagues/league/${data?.id}`
        )
      }
      className={`flex flex-col cursor-pointer`}
    >
      <p className="underline text-nowrap">View League</p>
      <p className="text-sm text-text-subGrey">View Details</p>
    </div>
  );
};
const JoinLeagueRender = ({ ...data }) => {
  const { userData } = useAuthStore.getState();
  const isJoined =
    data?.team?.filter(
      (item: any) => (item?.userId || item?.userLeague?.userId) === userData?.id
    )?.length || data?.userLeague?.userId === userData?.id;

  return (
    <>
      {!isJoined && data?.status === "registration" && (
        <LeagueRegistrationModal league={data} />
      )}
    </>
  );
};

const DateRender = ({ ...data }) => {
  return (
    <div
      onClick={() => data?.navigate(`/leagues/league/${data?.id}`)}
      className={`flex flex-col cursor-pointer`}
    >
      <p>{formatDateTimeInTimeZone(data?.draftDateTime, "LL")}</p>
      <p className="text-sm text-text-subGrey">Draft Date</p>
    </div>
  );
};

const useUserLeaguesHook = () => {
  const navigate = useNavigate();
  const { filter, onChangeFilter } = useFilter({});
  const { data, isFetching } = useFetchLeaguesList({
    page: filter?.page,
    limit: filter?.limit,
    numberOfTeams:
      filter?.numberOfTeams !== "All" ? filter?.numberOfTeams : null,
    isPPR:
      filter?.receivingOption !== "All"
        ? filter?.receivingOption === "PPR"
          ? true
          : filter?.receivingOption === "Non-PPR"
          ? false
          : null
        : null,
    name: filter?.keywords,
    entryFee:
      filter?.entryFeeUsers !== "All"
        ? filter?.entryFeeUsers
            // @ts-ignore
            ?.replace(/\$/g, "")
            ?.toLocaleLowerCase()
        : null,
    status: filter?.status !== "All" ? filter?.status : null,
    createdAtSort:
      filter?.createdAtSort !== "All"
        ? filter?.createdAtSort === "Newest first"
          ? "desc"
          : filter?.createdAtSort === "Oldest first"
          ? "asc"
          : null
        : null,
  });
  const { data: myLeagues } = useFetchMyLeaguesList({});

  const [leagues, setLeagues] = useState(data?.message ?? []);
  const [lastLeague, setLastLeague] = useState(
    data?.message?.[data?.message?.length - 1]
  );

  const [showOpenLeagues, setOpenLeagues] = useState(false);

  const [isShowMyLeagues, setIsShowMyLeagues] = useState(false);

  const onShowMyLeagues = () => {
    setIsShowMyLeagues(!isShowMyLeagues);
    setLeagues(!isShowMyLeagues ? myLeagues?.message?.league : data?.message);
    setLastLeague(
      !isShowMyLeagues
        ? myLeagues?.message?.league?.[myLeagues?.message?.league?.length - 1]
        : data?.message?.[data?.message?.length - 1]
    );
  };

  const onFilterValues = () => {
    const filteredLeagues = isShowMyLeagues
      ? myLeagues?.message?.league
      : data?.message.filter((item: any) => {
          return item;
        });
    setLeagues(filteredLeagues);
    setLastLeague(filteredLeagues?.[filteredLeagues?.length - 1]);
  };

  const onShowJustOpen = () => {
    onChangeFilter({ status: !showOpenLeagues ? "registration" : "All" });
    setOpenLeagues(!showOpenLeagues);
  };

  useEffect(() => {
    onFilterValues();
  }, [
    filter?.numberOfTeams,
    filter?.receivingOption,
    filter?.draftDateTime,
    filter?.keywords,
    filter?.entryFeeUsers,
    filter?.createdAtSort,
    data?.message,
  ]);

  const filters = [
    {
      name: "Search",
      value: "keywords",
    },
    {
      name: "Entry Fee",
      value: "entryFeeUsers",
      filters: ["All", "Free", "$0-$5", "$5-$10", "$10-$25", "$25+"],
    },
    {
      name: "Number of Teams",
      value: "numberOfTeams",
      filters: ["All", "2-4", "4-10", "10-16", "17-32"],
    },
    {
      name: "League Type",
      value: "receivingOption",
      filters: ["All", "PPR", "Non-PPR"],
    },
    {
      name: "Date",
      value: "createdAtSort",
      filters: ["All", "Newest first", "Oldest first"],
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_: string, record: any) => {
        return <NameRender {...record} />;
      },
      sort: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_: string, record: any) => {
        return <StatusRender {...record} />;
      },
      sort: true,
    },
    {
      title: "Entry Fee",
      dataIndex: "entryFee",
      render: (_: string, record: any) => {
        return <EntryFeeRender {...record} />;
      },
      sort: true,
    },
    {
      title: "Teams",
      dataIndex: "teams",
      render: (_: string, record: any) => {
        return <TeamsRender {...record} />;
      },
      sort: true,
    },
    {
      title: "Prize Fund",
      dataIndex: "prizeFund",
      render: (_: string, record: any) => {
        return <PrizeRender {...record} lastLeague={lastLeague} />;
      },
      sort: true,
    },
    {
      title: "View League",
      dataIndex: "viewLeague",
      render: (_: string, record: any) => {
        return <ViewLeagueRender navigate={navigate} {...record} />;
      },
      sort: true,
    },
    {
      title: "Join League",
      dataIndex: "joinLeague",
      render: (_: string, record: any) => {
        return <JoinLeagueRender {...record} />;
      },
      sort: true,
    },
    {
      title: "Draft Date",
      dataIndex: "date",
      render: (_: string, record: any) => {
        return <DateRender {...record} />;
      },
      sort: true,
    },
    {
      title: "Eliminated",
      dataIndex: "eliminated",
      render: (_: string, record: any) => {
        return <EliminatedRender {...record} />;
      },
      sort: true,
    },
  ];

  return {
    columns,
    isFetching,
    filters,
    leagues,
    onShowJustOpen,
    onChangeFilter,
    filter,
    onShowMyLeagues,
    isShowMyLeagues,
    showOpenLeagues,
  };
};

export default useUserLeaguesHook;
