import useAuthStore from "../../../../stores/auth/auth";
import { useFetchAvailablePlayers } from "../../../../queries/playerQueries/playerQueries";
import { useEffect, useState } from "react";

const useLoadPlayers = (leagueId: string) => {
  const { setAllPlayers } = useAuthStore.getState();
  const { allPlayers } = useAuthStore(["allPlayers"]);
  const [limit, _setLimit] = useState(1000);
  const [offset, _setOffset] = useState(allPlayers?.offset ?? 0);

  const {
    data: avbPlayers,
    refetch,
    isFetching,
  } = useFetchAvailablePlayers(leagueId!, {
    page: 1,
    limit: limit,
    offset: offset,
  });

  useEffect(() => {
    if (!allPlayers?.players?.length && avbPlayers?.length) {
      setAllPlayers({
        offset: offset + 1000,
        players: avbPlayers,
      });
    }
  }, [avbPlayers]);

  const getNextPlayers = async () => {
    await refetch();

    if (avbPlayers?.length) {
      setAllPlayers({
        offset: offset + 1000,
        players: [...allPlayers?.players, ...avbPlayers],
      });
    }
  };

  return { avbPlayers, getNextPlayers, isFetching };
};

export default useLoadPlayers;
