import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { LEAGUES_QUERY_KEY } from "./leaguesQueriesKeys";
import { LeaguesServices } from "../../services/apis/Leagues/LeaguesServices";
import useAuthStore from "../../stores/auth/auth";

export const useFetchLeaguesList = (params: any) => {
  const queryResult = useQuery<any>({
    queryKey: [LEAGUES_QUERY_KEY.FETCH_LEAGUES_LIST, params],
    queryFn: () => LeaguesServices.getAllLeagues(params),
    refetchOnWindowFocus: false,
    initialData: {
      message: [],
    },
    retry: 0,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useFetchMyLeaguesList = (params: any) => {
  const { loginData } = useAuthStore.getState();
  const queryResult = useQuery<any>({
    queryKey: [LEAGUES_QUERY_KEY.FETCH_MY_LEAGUES_LIST, params],
    queryFn: () => LeaguesServices.getMyLeagues(params),
    refetchOnWindowFocus: false,
    initialData: {
      message: [],
    },
    retry: 0,
    enabled: !!loginData?.access_token,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useFetchLeague = (id: string, params?: any) => {
  const queryResult = useQuery<any>({
    queryKey: [LEAGUES_QUERY_KEY.FETCH_LEAGUE, id, params],
    queryFn: () => LeaguesServices.getLeagueById(id, params),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!id,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};
export const useFetchLeagueCurrentWeek = (id: string) => {
  const queryResult = useQuery<any>({
    queryKey: [LEAGUES_QUERY_KEY.FETCH_LEAGUE_CURRENT_WEEK],
    queryFn: () => LeaguesServices.getLeagueById(id),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!id,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useFetchSelectedLeaguesPost = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: LeaguesServices.postSelectedLeagues,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [LEAGUES_QUERY_KEY.UPDATE_LEAGUE],
      });
    },
    onError: (error) => {
      console.log("Error", error);
    },
  });
};

export const useUpdateLeague = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: LeaguesServices.updateLeague,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [LEAGUES_QUERY_KEY.FETCH_LEAGUES_LIST],
      });
    },
  });
};

export const useCreateLeague = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: LeaguesServices.createLeague,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [LEAGUES_QUERY_KEY.FETCH_LEAGUES_LIST],
      });
    },
  });
};

export const useJoinLeague = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: LeaguesServices.joinLeague,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          LEAGUES_QUERY_KEY.FETCH_LEAGUE,
          LEAGUES_QUERY_KEY.FETCH_LEAGUES_LIST,
        ],
      });
    },
  });
};
export const useCreateLeaguePhoto = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: LeaguesServices.createLeaguePhoto,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [LEAGUES_QUERY_KEY.FETCH_LEAGUES_LIST],
      });
    },
  });
};
export const useDeleteLeague = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: LeaguesServices.deleteLeague,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [LEAGUES_QUERY_KEY.FETCH_LEAGUES_LIST],
      });
    },
  });
};

export const useFetchEliminationDate = () => {
  const queryResult = useQuery<any>({
    queryKey: [LEAGUES_QUERY_KEY.FETCH_ELIMINATION_DATE],
    queryFn: () => LeaguesServices.getEliminationDate(),
    refetchOnWindowFocus: false,
    retry: 0,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};
export const useFetchCurrentWeek = () => {
  const queryResult = useQuery<any>({
    queryKey: [LEAGUES_QUERY_KEY.FETCH_CURRENT_WEEK],
    queryFn: () => LeaguesServices.getCurrentWeek(),
    refetchOnWindowFocus: false,
    retry: 0,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};
