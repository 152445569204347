import { forwardRef } from "react";
import clsx from "clsx";
import { DescriptionView, ErrorMsg, Label, TextArea } from "../index";
import { LabeledTextAreaPropsType } from "./type";

const LabeledTextArea = forwardRef<
  HTMLTextAreaElement,
  LabeledTextAreaPropsType
>(
  (
    {
      label,
      error,
      labelClassName,
      errorClassName,
      containerClassName,
      inputContainerClassName,
      description,
      ...rest
    }: LabeledTextAreaPropsType,
    ref
  ) => {
    return (
      <div className={clsx("flex flex-col gap-2 w-full", containerClassName)}>
        {!!label && (
          <Label className={labelClassName} htmlFor={rest?.id ?? rest?.name}>
            {label}
          </Label>
        )}
        <TextArea
          {...rest}
          ref={ref}
          containerClassName={inputContainerClassName}
          error={error}
          id={rest?.id ?? rest?.name}
        />
        {description && <DescriptionView description={description} />}
        {error && <ErrorMsg className={errorClassName} error={error} />}
      </div>
    );
  }
);
export default LabeledTextArea;
