import { Button, Input, Modal, useModal } from "../../../../components";
import { ReactComponent as Search } from "../../../../assets/icons/search-icon.svg";
import TableCardsListing from "../../../../components/TableCardsListing/TableCardsListing";
import clsx from "clsx";
import useAvailablePlayers from "../hooks/useAvailablePlayers";
import { Oval } from "react-loader-spinner";
import { numberWithCommas } from "../../../../utils/helpers";

export const AvailablePlayersModal = ({
  leagueId,
  team,
  refetchTeam,
  isAdmin = false,
}: {
  leagueId: string;
  team?: any;
  refetchTeam?: () => void;
  isAdmin?: boolean;
}) => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <AvailablePlayers
      team={team}
      leagueId={leagueId}
      closeModal={closeModal}
      refetchTeam={refetchTeam}
      isAdmin={isAdmin}
    />
  );
  const { modalControl, openModal } = useModal({
    title: "Available Players",
    content: addContent,
    size: "xxl",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      <Button
        mode="primary"
        onClick={openModal}
        className="rounded-[8px] !w-full px-4 text-nowrap !h-10 md:!w-auto"
      >
        View Available Players
      </Button>
    </>
  );
};

const AvailablePlayers = ({
  leagueId,
  team,
  refetchTeam,
  isAdmin,
}: {
  closeModal?: () => void;
  leagueId: string;
  team?: any;
  refetchTeam?: () => void;
  isAdmin?: boolean;
}) => {
  const {
    isFetching,
    activeFilter,
    playersTypeFilter,
    allPlayers,
    handlePlayersType,
    columsPlayers,
    setActiveFilter,
    loadMore,
    allPlayersForFilter,
    setPlayersTypeFilter,
    setSearchName,
    searchPlayer,
    isPlayersLoading,
  } = useAvailablePlayers({ leagueId, team, refetchTeam, isAdmin });

  return (
    <>
      <div className="hidden w-full h-full rounded-[12px] mt-4 md:block">
        <div className="flex justify-between items-center">
          <div className="bg-gradient-to-br from-card-bgHover-1 to-card-bgHover-2 rounded-[6px] flex items-center p-[2px]">
            <div
              onClick={() => setActiveFilter("All")}
              className={clsx(
                "flex flex-col justify-center items-center cursor-pointer py-1 px-4 w-auto h-[36px] rounded-[6px] text-nowrap",
                activeFilter === "All" &&
                  "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
              )}
            >
              <p className="font-gilroyBold">All</p>
            </div>
            {!(activeFilter === "All" || activeFilter === "My bids") && (
              <div className="h-[32px] w-[1px] bg-card-stroke" />
            )}
            {!isAdmin && (
              <div
                onClick={() => {
                  setActiveFilter("My bids");
                  setPlayersTypeFilter("All");
                }}
                className={clsx(
                  "flex flex-col justify-center items-center cursor-pointer py-1 px-4 w-auto h-[36px] rounded-[6px] text-nowrap",
                  activeFilter === "My bids" &&
                    "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
                )}
              >
                <p className="font-gilroyBold">My bids</p>
              </div>
            )}
            {!(activeFilter === "My bids" || activeFilter === "Saved") && (
              <div className="h-[32px] w-[1px] bg-card-stroke" />
            )}
            {!isAdmin && (
              <div
                onClick={() => {
                  setActiveFilter("Saved");
                  setPlayersTypeFilter("All");
                }}
                className={clsx(
                  "flex flex-col justify-center items-center cursor-pointer py-1 px-4 w-auto h-[36px] rounded-[6px] text-nowrap",
                  activeFilter === "Saved" &&
                    "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
                )}
              >
                <p className="font-gilroyBold">Saved</p>
              </div>
            )}
          </div>
          <div className="flex items-center">
            <p className="text-text-grey text-base">
              Free Agent Acquisition Budget:
            </p>
            <span className="text-text-white text-[24px] ml-1">
              ${numberWithCommas(team?.faab_balance)}
            </span>
            <span className="text-text-grey text-base">/$1,000</span>
          </div>
        </div>

        <div className="w-full rounded-t-[12px] flex justify-between items-center gap-2 mt-4">
          <div className="bg-gradient-to-br from-card-top-1 to-card-top-2 rounded-[6px] flex items-center">
            <div
              onClick={() => handlePlayersType("All")}
              className={clsx(
                "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[64px] h-[48px] rounded-[6px]",
                playersTypeFilter === "All" &&
                  "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
              )}
            >
              <p className="font-gilroyBold">All</p>
            </div>
            {!(playersTypeFilter === "All" || playersTypeFilter === "QB") && (
              <div className="h-[32px] w-[1px] bg-elements-chipGradient-1" />
            )}
            <button
              disabled={activeFilter !== "All"}
              onClick={() => handlePlayersType("QB")}
              className={clsx(
                "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[64px] h-[48px] rounded-[6px]",
                playersTypeFilter === "QB" &&
                  "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
              )}
            >
              <p className="font-gilroyBold">QB</p>
            </button>
            {!(playersTypeFilter === "QB" || playersTypeFilter === "RB") && (
              <div className="h-[32px] w-[1px] bg-elements-chipGradient-1" />
            )}
            <button
              disabled={activeFilter !== "All"}
              onClick={() => handlePlayersType("RB")}
              className={clsx(
                "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[64px] h-[48px] rounded-[6px]",
                playersTypeFilter === "RB" &&
                  "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
              )}
            >
              <p className="font-gilroyBold">RB</p>
            </button>
            {!(playersTypeFilter === "RB" || playersTypeFilter === "WR") && (
              <div className="h-[32px] w-[1px] bg-elements-chipGradient-1" />
            )}
            <button
              disabled={activeFilter !== "All"}
              onClick={() => handlePlayersType("WR")}
              className={clsx(
                "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[64px] h-[48px] rounded-[6px]",
                playersTypeFilter === "WR" &&
                  "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
              )}
            >
              <p className="font-gilroyBold">WR</p>
            </button>
            {!(playersTypeFilter === "WR" || playersTypeFilter === "TE") && (
              <div className="h-[32px] w-[1px] bg-elements-chipGradient-1" />
            )}
            <button
              disabled={activeFilter !== "All"}
              onClick={() => handlePlayersType("TE")}
              className={clsx(
                "flex flex-col justify-center items-center cursor-pointer py-1 px-2 w-[64px] h-[48px] rounded-[6px]",
                playersTypeFilter === "TE" &&
                  "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
              )}
            >
              <p className="font-gilroyBold">TE</p>
            </button>
          </div>
          <div className="w-full">
            <Input
              containerClassName="!h-[48px] rounded-[8px] border-r-0 pr-0 bg-transparent focus-within:bg-transparent"
              inputClassName="placeholder-text-gray text-sm placeholder-font-gilroyMedium"
              placeholder="Find Player..."
              rightIcon={
                <div className="bg-gradient-to-br from-red-bgGradient-1 to-red-bgGradient-2 h-[48px] w-[48px] flex justify-center items-center rounded-[8px] cursor-pointer">
                  <Search
                    className="w-6 h-6 cursor-pointer"
                    onClick={searchPlayer}
                  />
                </div>
              }
              onChange={(e) => setSearchName(e.target.value)}
              onKeyDown={(e) => {
                if (e?.key === "Enter") searchPlayer();
              }}
            />
          </div>
        </div>
        <div className="mt-5 w-full h-[500px] overflow-hidden overflow-y-auto scrollbar">
          <TableCardsListing
            columns={columsPlayers}
            onClickRow={() => {}}
            dataSource={
              activeFilter === "My bids"
                ? allPlayersForFilter.sort(
                    (a: any, b: any) => a?.bid?.priority - b?.bid?.priority
                  )
                : allPlayersForFilter ?? []
            }
            overrideTableContainerClass="!overflow-y-scroll mt-4 !h-full"
            containerClass="border-0"
            loading={isFetching}
            isPlayers
          />
        </div>
        {allPlayers?.players?.length &&
        playersTypeFilter === "All" &&
        activeFilter === "All" ? (
          <div className="w-full flex justify-center mt-5">
            <Button
              disabled={isFetching || isPlayersLoading}
              leftIcon={
                <Oval
                  visible={isFetching || isPlayersLoading}
                  height="20"
                  width="20"
                  color="#000000"
                  secondaryColor="#ffffff"
                />
              }
              className="rounded-[8px] !max-h-[40px]"
              onClick={() => loadMore()}
            >
              Load more
            </Button>
          </div>
        ) : null}
      </div>

      <div className="relative w-[100%] block rounded-[12px] mt-4 md:hidden">
        <div className="w-[100%] flex flex-col items-start gap-2 overflow-hidden">
          <div className="flex flex-col items-start gap-2">
            <p className="text-text-grey text-base">
              Free Agent Acquisition Budget:
            </p>
            <div className="flex items-center gap-2">
              <span className="text-text-white text-[24px] ml-1">
                ${numberWithCommas(team?.faab_balance)}
              </span>
              <span className="text-text-grey text-base">/$1,000</span>
            </div>
          </div>
          <div className="w-[100%]">
            <Input
              containerClassName="!h-[48px] rounded-[8px] border-r-0 pr-0 bg-transparent focus-within:bg-transparent"
              inputClassName="placeholder-text-gray text-sm placeholder-font-gilroyMedium"
              placeholder="Find Player..."
              rightIcon={
                <div className="bg-gradient-to-br from-red-bgGradient-1 to-red-bgGradient-2 h-[48px] w-[48px] flex justify-center items-center rounded-[8px] cursor-pointer">
                  <Search
                    className="w-6 h-6 cursor-pointer"
                    onClick={searchPlayer}
                  />
                </div>
              }
              onChange={(e) => setSearchName(e.target.value)}
              onKeyDown={(e) => {
                if (e?.key === "Enter") searchPlayer();
              }}
            />
          </div>
          <div className="w-[100%] relative h-[300px] overflow-hidden overflow-scroll scrollbar-hide">
            <div className="absolute top-0 left-0 h-full w-[980px]">
              <TableCardsListing
                columns={columsPlayers}
                onClickRow={() => {}}
                dataSource={
                  activeFilter === "My bids"
                    ? allPlayersForFilter?.sort(
                        (a: any, b: any) => a?.bid?.priority - b?.bid?.priority
                      )
                    : allPlayersForFilter ?? []
                }
                overrideTableContainerClass="!overflow-y-scroll mt-4 !h-[45vh]"
                containerClass="border-0"
                loading={isFetching}
                isPlayers
              />
            </div>
          </div>
          {allPlayers?.players?.length &&
          playersTypeFilter === "All" &&
          activeFilter === "All" ? (
            <div className="w-full flex justify-center mt-3">
              <Button
                disabled={isFetching || isPlayersLoading}
                leftIcon={
                  <Oval
                    visible={isFetching || isPlayersLoading}
                    height="20"
                    width="20"
                    color="#000000"
                    secondaryColor="#ffffff"
                  />
                }
                className="rounded-[8px] !max-h-[40px]"
                onClick={() => loadMore()}
              >
                Load more
              </Button>
            </div>
          ) : null}
          <div className="rounded-t-[12px] flex justify-between items-center gap-2 mt-4">
            <div className="bg-gradient-to-br from-card-top-1 to-card-top-2 rounded-[6px] flex items-center">
              <div
                onClick={() => handlePlayersType("All")}
                className={clsx(
                  "flex flex-col justify-center items-center cursor-pointer py-1 px-4 h-[48px] rounded-[6px]",
                  playersTypeFilter === "All" &&
                    "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
                )}
              >
                <p className="font-gilroyBold">All</p>
              </div>
              {!(playersTypeFilter === "All" || playersTypeFilter === "QB") && (
                <div className="h-[32px] w-[1px] bg-elements-chipGradient-1" />
              )}
              <button
                disabled={activeFilter !== "All"}
                onClick={() => handlePlayersType("QB")}
                className={clsx(
                  "flex flex-col justify-center items-center cursor-pointer py-1 px-4 h-[48px] rounded-[6px]",
                  playersTypeFilter === "QB" &&
                    "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
                )}
              >
                <p className="font-gilroyBold">QB</p>
              </button>
              {!(playersTypeFilter === "QB" || playersTypeFilter === "RB") && (
                <div className="h-[32px] w-[1px] bg-elements-chipGradient-1" />
              )}
              <button
                disabled={activeFilter !== "All"}
                onClick={() => handlePlayersType("RB")}
                className={clsx(
                  "flex flex-col justify-center items-center cursor-pointer py-1 px-4 h-[48px] rounded-[6px]",
                  playersTypeFilter === "RB" &&
                    "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
                )}
              >
                <p className="font-gilroyBold">RB</p>
              </button>
              {!(playersTypeFilter === "RB" || playersTypeFilter === "WR") && (
                <div className="h-[32px] w-[1px] bg-elements-chipGradient-1" />
              )}
              <button
                disabled={activeFilter !== "All"}
                onClick={() => handlePlayersType("WR")}
                className={clsx(
                  "flex flex-col justify-center items-center cursor-pointer py-1 px-4 h-[48px] rounded-[6px]",
                  playersTypeFilter === "WR" &&
                    "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
                )}
              >
                <p className="font-gilroyBold">WR</p>
              </button>
              {!(playersTypeFilter === "WR" || playersTypeFilter === "TE") && (
                <div className="h-[32px] w-[1px] bg-elements-chipGradient-1" />
              )}
              <button
                disabled={activeFilter !== "All"}
                onClick={() => handlePlayersType("TE")}
                className={clsx(
                  "flex flex-col justify-center items-center cursor-pointer py-1 px-4 h-[48px] rounded-[6px]",
                  playersTypeFilter === "TE" &&
                    "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
                )}
              >
                <p className="font-gilroyBold">TE</p>
              </button>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div className="bg-gradient-to-br from-card-bgHover-1 to-card-bgHover-2 rounded-[6px] flex items-center p-[2px]">
              <div
                onClick={() => setActiveFilter("All")}
                className={clsx(
                  "flex flex-col justify-center items-center cursor-pointer py-1 px-4 w-auto h-[36px] rounded-[6px] text-nowrap",
                  activeFilter === "All" &&
                    "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
                )}
              >
                <p className="font-gilroyBold">All</p>
              </div>
              {!(activeFilter === "All" || activeFilter === "My bids") && (
                <div className="h-[32px] w-[1px] bg-card-stroke" />
              )}
              {!isAdmin && (
                <div
                  onClick={() => {
                    setActiveFilter("My bids");
                    setPlayersTypeFilter("All");
                  }}
                  className={clsx(
                    "flex flex-col justify-center items-center cursor-pointer py-1 px-4 w-auto h-[36px] rounded-[6px] text-nowrap",
                    activeFilter === "My bids" &&
                      "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
                  )}
                >
                  <p className="font-gilroyBold">My bids</p>
                </div>
              )}
              {!(activeFilter === "My bids" || activeFilter === "Saved") && (
                <div className="h-[32px] w-[1px] bg-card-stroke" />
              )}
              {!isAdmin && (
                <div
                  onClick={() => {
                    setActiveFilter("Saved");
                    setPlayersTypeFilter("All");
                  }}
                  className={clsx(
                    "flex flex-col justify-center items-center cursor-pointer py-1 px-4 w-auto h-[36px] rounded-[6px] text-nowrap",
                    activeFilter === "Saved" &&
                      "bg-gradient-to-br from-red-accent-1 to-red-accent-2"
                  )}
                >
                  <p className="font-gilroyBold">Saved</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AvailablePlayersModal;
