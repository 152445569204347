import { useUserById } from "../../../../queries/adminQueries/adminQueries";
import demo_team_logo from "../../../../assets/images/demoTeamLogo.png";
import clsx from "clsx";

const TransactionCard = ({ transaction }: { transaction: any }) => {
  const { data } = useUserById(transaction?.userId);

  return (
    <div className="flex justify-between items-center my-2">
      <div className="flex items-center">
          <img
            src={data?.profileUrl || demo_team_logo}
            alt="CutThroat"
            className="w-[38px] h-[38px] rounded-[50px] mr-2"
          />
          <div>
            <p className="text-base font-gilroyBold">{data?.name}</p>
            <p className="text-text-grey text-xs font-gilroyMedium capitalize">
              {transaction?.type}
            </p>
          </div>
        </div>
      <p
        className={clsx(
          "text-text-red font-gilroyBold",
          (transaction?.type === "deposit" ||
            transaction?.type?.toLocaleLowerCase()?.includes("win") ||
            transaction?.type === "deposit from admin" ||
            transaction?.type === "transfer from admin" ||
            transaction?.type === "credit from admin") &&
            "!text-text-green"
        )}
      >
        {transaction?.type === "deposit" ||
        transaction?.type?.toLocaleLowerCase()?.includes("win") ||
        transaction?.type === "deposit from admin" ||
        transaction?.type === "transfer from admin" ||
        transaction?.type === "credit from admin"
          ? "+"
          : "-"}
        ${transaction?.amount}
      </p>
    </div>
  );
};
export default TransactionCard;
