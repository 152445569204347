import { AddNewLeagueModal } from "../Leagues/components/AddNewLeague";
import useLeagueHook from "./hooks/useLeague";
import demo_logo from "../../../assets/images/ball-logo.png";
import CustomBox from "./components/CustomBox";
import moment from "moment-timezone";
import { OpenSlot, TeamCard } from "./components/TeamCard";
import { DeleteLeagueModal } from "./components/DeleteLeague";
import { LaunchDraftModal } from "./components/LaunchDraft";
import { CloseRegistrationModal } from "./components/CloseRegistration";
import LeagueRegistrationModal from "./components/LeagueRegistration";
import LiveChat from "../Team/components/LiveChat";
import { Button } from "../../../components";
import { InviteUsersModal } from "./components/InviteUsers";
import MoveTeamsModal from "../League/components/MoveTeams.tsx";
import { ArchiveLeagueModal } from "./components/ArchiveLeague";
import {
  formatDateTimeInTimeZone,
  getTimezoneAbbr,
  isValidImg,
} from "../../../utils/helpers.ts";
import clsx from "clsx";
import LeagueLogs from "./components/LeagueLogs.tsx";
import { Oval } from "react-loader-spinner";

const League = () => {
  const {
    handleNumber,
    data,
    isJoined,
    inProgress,
    regularTeams,
    teamsAtRisk,
    eliminatedTeams,
    id,
    isAdmin,
    userData,
    navigate,
    completed,
    eliminationDate,
    setWeek,
    weekSlots,
    week,
    getTeamOwner,
    prjSlots,
    currentLeagueWeek,
    leagueCurrentWeek,
    isFetchingEliminationDate,
    isFetching,
    orangeZoneTeams,
  } = useLeagueHook();

  return (
    <>
      <div className="px-10 pb-6 hidden xl:block">
        <div className="w-full flex flex-col md:flex-row justify-between items-center">
          <div className="w-[46%] flex flex-row justify-start items-center">
            <img
              src={isValidImg(data?.league?.logoUrl) || demo_logo}
              alt="CutThroat"
              className="w-[100px] h-[100px] rounded-[50px] mr-6"
            />
            <div className="w-full">
              <h2 className="font-absoluteEmpire text-[30px] leading-none">
                {data?.league?.name}
              </h2>
            </div>
          </div>
          <div className="w-[52%] flex flex-row justify-end items-end gap-4">
            {isAdmin ? (
              <>
                <AddNewLeagueModal isEdit />
                {data?.league?.status === "registration" && (
                  <CloseRegistrationModal />
                )}
                {data?.league?.status === "registration" && (
                  <LaunchDraftModal />
                )}
                <DeleteLeagueModal />
                {data?.league?.status === "registration" && (
                  <InviteUsersModal leagueId={id} />
                )}
                {data?.league?.status === "completed" && (
                  <ArchiveLeagueModal leagueId={id} />
                )}
              </>
            ) : (
              <>
                {!isJoined && data?.league?.status === "registration" && (
                  <LeagueRegistrationModal
                    league={data?.league}
                    isLeagueView={true}
                  />
                )}
              </>
            )}
            {isJoined ? (
              <Button
                className="rounded-[8px] px-4 !h-10"
                onClick={() =>
                  navigate(`/draft/${data?.league?.draft?.[0]?.id}`)
                }
              >
                {data?.league?.draft?.[0]?.status !== "scheduled"
                  ? "Draft Results"
                  : " Go To Draft"}
              </Button>
            ) : null}
          </div>
        </div>
        <div className="mt-[32px] flex gap-3">
          <CustomBox
            title="League Status"
            data={data?.league?.status}
            className="capitalize max-w-[14%] w-full"
          />
          <CustomBox
            title={
              data?.league?.status !== "registration"
                ? "Current Progress"
                : "Creation Date"
            }
            data={
              data?.league?.status !== "registration"
                ? `Week ${currentLeagueWeek || "-"}`
                : moment(data?.league?.createdAt).format("LL")
            }
            className="capitalize max-w-[16%] w-full"
          />
          <CustomBox
            title="Entry Fee"
            data={`$${handleNumber(data?.league?.entryFee) ?? 0} per team`}
            className="capitalize max-w-[18%] w-full"
          />

          <div
            className={
              "max-w-[22%] min-h-[88px] bg-card-bgGreyDark rounded-[12px] border-[1px] border-modal-modalStroke py-[15px] px-5 flex justify-between gap-4"
            }
          >
            <div className="w-fit flex flex-col justify-between gap-1 pr-2">
              <p className="text-text-subGrey font-gilroyMedium whitespace-nowrap">
                1st Place Prize
              </p>
              <p className="font-gilroyBold text-[16px] xl:text-[20px] whitespace-nowrap mt-6">
                {`$${handleNumber(data?.league?.prizeFundFirstPlace) ?? 0}`}
              </p>
            </div>
            <div className="w-fit flex flex-col justify-between gap-1 pl-2">
              <p className="text-text-subGrey font-gilroyMedium whitespace-nowrap">
                2nd Place Prize
              </p>
              <p className="font-gilroyBold text-[16px] xl:text-[20px] whitespace-nowrap mt-6">
                {`$${handleNumber(data?.league?.prizeFundSecondPlace) ?? 0}`}
              </p>
            </div>
          </div>
          <div className="max-w-[25%] w-full min-h-[88px] bg-card-bgGreyDark rounded-[12px] border-[1px] border-modal-modalStroke py-[15px] px-5 flex flex-row justify-between gap-4">
            <div className="flex flex-col justify-between">
              <p className="text-text-subGrey font-gilroyMedium">
                {data?.league?.draft?.[0]?.status !== "scheduled"
                  ? "Number Of Teams Eliminated"
                  : "Draft Format"}
              </p>
              <p className="font-gilroyBold text-[20px]">
                {data?.league?.draft?.[0]?.status !== "scheduled"
                  ? data?.totalEliminatedTeams
                  : "Snake"}
              </p>
            </div>
            <div className="flex flex-col justify-between">
              <p className="text-text-subGrey font-gilroyMedium">
                {data?.league?.draft?.[0]?.status !== "scheduled" &&
                eliminationDate
                  ? "Next Elimination Date"
                  : "Draft Date"}
              </p>
              {isFetchingEliminationDate || isFetching ? (
                <Oval
                  visible={isFetchingEliminationDate || isFetching}
                  height="20"
                  width="20"
                  color="#000000"
                  secondaryColor="#ffffff"
                />
              ) : (
                <p className="font-gilroyBold text-[20px]">
                  {data?.league?.draft?.[0]?.status !== "scheduled" &&
                  eliminationDate
                    ? formatDateTimeInTimeZone(eliminationDate, "MMM DD")
                    : formatDateTimeInTimeZone(
                        data?.league?.draftDateTime,
                        "MMM DD"
                      )}
                </p>
              )}
            </div>
            <div className="flex flex-col justify-between">
              <p className="text-text-subGrey font-gilroyMedium">Time</p>
              {isFetchingEliminationDate || isFetching ? (
                <Oval
                  visible={isFetchingEliminationDate || isFetching}
                  height="20"
                  width="20"
                  color="#000000"
                  secondaryColor="#ffffff"
                />
              ) : (
                <p className="font-gilroyBold text-[20px]">
                  {data?.league?.draft?.[0]?.status !== "scheduled" &&
                  eliminationDate
                    ? formatDateTimeInTimeZone(eliminationDate, "LT")
                    : formatDateTimeInTimeZone(
                        data?.league?.draftDateTime,
                        "LT"
                      )}{" "}
                  {getTimezoneAbbr()}
                </p>
              )}
            </div>
          </div>
          <CustomBox
            title="Teams"
            data={`${data?.league?.team?.length ?? 0} / ${
              data?.league?.numberOfTeams ?? 0
            }`}
            className="max-w-[7%] w-full min-w-[90px]"
          />
        </div>
        <div className="flex gap-6 mt-[32px]">
          <div
            className={clsx("w-[70%]", !isJoined && !isAdmin && "!w-[100%]")}
          >
            <div className="relative px-6 py-4 bg-filterContainer border-[1px] border-modal-modalStroke rounded-t-[12px] flex flex-row justify-between items-center">
              <p className="font-gilroyBold">Teams</p>
              {data?.league?.status !== "registration" && (
                <div className="flex overflow-x-scroll ml-4 gap-2 scrollbar">
                  {weekSlots?.map((item: number) => (
                    <button
                      key={item}
                      disabled={item + 1 < data?.league?.createdAtWeek}
                      className={clsx(
                        "cursor-pointer bg-card-bgGreyLight rounded-[8px] w-auto px-4 py-3 text-nowrap hover:bg-gradient-to-br hover:from-card-bgHover-1 hover:to-card-bgHover-2",
                        (week ? week : currentLeagueWeek) === item + 1 &&
                          "bg-gradient-to-br from-card-bgHover-1 to-card-bgHover-2",
                        item + 1 < data?.league?.createdAtWeek && "opacity-50"
                      )}
                      onClick={() => setWeek(item + 1)}
                    >
                      <p
                        className={clsx(
                          "text-[12px] font-gilroyBold",
                          currentLeagueWeek === item + 1 && "text-green-1"
                        )}
                      >
                        Week {item + 1}
                      </p>
                    </button>
                  ))}
                  {prjSlots?.map((item: number) => (
                    <button
                      key={item}
                      disabled={
                        currentLeagueWeek + item + 1 > currentLeagueWeek
                      }
                      className={clsx(
                        "cursor-pointer bg-card-bgGreyLight rounded-[8px] w-auto px-4 py-3 text-nowrap hover:bg-gradient-to-br hover:from-card-bgHover-1 hover:to-card-bgHover-2",
                        (week ? week : currentLeagueWeek) ===
                          currentLeagueWeek + item + 1 &&
                          "bg-gradient-to-br from-card-bgHover-1 to-card-bgHover-2",
                        currentLeagueWeek + item + 1 > currentLeagueWeek &&
                          "opacity-50"
                      )}
                      onClick={() => setWeek(currentLeagueWeek + item + 1)}
                    >
                      <p className="text-[12px] font-gilroyBold">
                        Week {currentLeagueWeek + item + 1}
                      </p>
                    </button>
                  ))}
                </div>
              )}
              {isAdmin && <MoveTeamsModal />}
            </div>
            <div
              className="w-[100%] flex flex-row flex-wrap justify-between bg-card-bgGreyDark rounded-b-[12px]
              border-[1px] border-modal-modalStroke p-6 overflow-hidden gap-4 w-[100%]"
            >
              {(teamsAtRisk?.length || eliminatedTeams?.length) &&
              data?.league?.team?.length
                ? regularTeams
                    ?.sort(
                      (a: any, b: any) =>
                        Number(b?.latestScore) - Number(a?.latestScore)
                    )
                    ?.map((item: any) => (
                      <TeamCard
                        key={item?.id}
                        name={item?.name}
                        status={item?.status}
                        position={item?.draftPosition}
                        score={item?.score}
                        logo={item?.logo_url}
                        id={item?.id}
                        isMe={item?.userId === userData?.id}
                        eliminatedWeek={item?.eliminatedAt}
                        leagueStatus={data?.league?.status}
                        faab={item?.faab_balance}
                        user={getTeamOwner(item?.userId)}
                        teamScore={item?.teamScore}
                        latestScore={item?.latestScore}
                        week={week}
                        isAdmin={isAdmin}
                        leagueId={id}
                      />
                    ))
                : data?.league?.team
                    ?.sort(
                      (a: any, b: any) =>
                        Number(b?.latestScore) - Number(a?.latestScore)
                    )
                    ?.map((item: any) => (
                      <TeamCard
                        key={item?.id}
                        name={item?.name}
                        status={item?.status}
                        position={item?.draftPosition}
                        score={item?.score}
                        logo={item?.logo_url}
                        id={item?.id}
                        isMe={item?.userId === userData?.id}
                        eliminatedWeek={item?.eliminatedAt}
                        leagueStatus={data?.league?.status}
                        faab={item?.faab_balance}
                        user={getTeamOwner(item?.userId)}
                        teamScore={item?.teamScore}
                        latestScore={item?.latestScore}
                        week={week}
                        isAdmin={isAdmin}
                        leagueId={id}
                      />
                    ))}
              {data?.league?.numberOfTeams > data?.league?.team?.length &&
              !inProgress &&
              !completed
                ? [
                    ...Array(
                      data?.league?.numberOfTeams - data?.league?.team?.length
                    ).keys(),
                  ]?.map((_item, index) => <OpenSlot key={index} />)
                : null}

              {orangeZoneTeams?.length ? (
                <div className="flex w-full gap-4 items-center">
                  <div className="h-1 w-full rounded-[4px] bg-gradient-to-br from-orange-4 to-orange-1" />
                  <div className="flex flex-col justify-center items-center">
                    <p className="text-orange-1 font-gilroyBold text-center text-nowrap">
                      Danger zone
                    </p>
                  </div>
                  <div className="h-1 w-full rounded-[4px] bg-gradient-to-br from-orange-1 to-orange-4" />
                </div>
              ) : null}
              {orangeZoneTeams?.length
                ? orangeZoneTeams
                    ?.sort(
                      (a: any, b: any) =>
                        Number(b?.latestScore) - Number(a?.latestScore)
                    )
                    ?.map((item: any) => (
                      <TeamCard
                        key={item?.id}
                        name={item?.name}
                        status={item?.status}
                        position={item?.draftPosition}
                        score={item?.score}
                        logo={item?.logo_url}
                        id={item?.id}
                        atOrangeZone
                        isMe={item?.userId === userData?.id}
                        eliminatedWeek={item?.eliminatedAt}
                        leagueStatus={data?.league?.status}
                        faab={item?.faab_balance}
                        user={getTeamOwner(item?.userId)}
                        teamScore={item?.teamScore}
                        latestScore={item?.latestScore}
                        week={week}
                        isAdmin={isAdmin}
                        leagueId={id}
                      />
                    ))
                : null}
              {teamsAtRisk?.length || eliminatedTeams?.length ? (
                <div className="flex w-full gap-4 items-center">
                  <div className="h-1 w-full rounded-[4px] bg-gradient-to-br from-red-bgGradient-1 to-red-bgGradient-2" />
                  <div className="flex flex-col justify-center items-center">
                    <p className="text-text-red font-gilroyBold">Risk zone</p>
                    <p className="text-text-red font-gilroyMedium text-xs text-nowrap">
                      (Eliminated —{" "}
                      {formatDateTimeInTimeZone(eliminationDate, "ll")})
                    </p>
                  </div>
                  <div className="h-1 w-full rounded-[4px] bg-gradient-to-br from-red-bgGradient-1 to-red-bgGradient-2" />
                </div>
              ) : null}
              {teamsAtRisk?.length
                ? teamsAtRisk
                    ?.sort(
                      (a: any, b: any) =>
                        Number(b?.latestScore) - Number(a?.latestScore)
                    )
                    ?.map((item: any) => (
                      <TeamCard
                        key={item?.id}
                        name={item?.name}
                        status={item?.status}
                        position={item?.draftPosition}
                        score={item?.score}
                        logo={item?.logo_url}
                        id={item?.id}
                        atRisk
                        isMe={item?.userId === userData?.id}
                        eliminatedWeek={item?.eliminatedAt}
                        leagueStatus={data?.league?.status}
                        faab={item?.faab_balance}
                        user={getTeamOwner(item?.userId)}
                        teamScore={item?.teamScore}
                        latestScore={item?.latestScore}
                        week={week}
                        isAdmin={isAdmin}
                        leagueId={id}
                      />
                    ))
                : null}
              {eliminatedTeams?.length
                ? eliminatedTeams?.map((item: any) => (
                    <TeamCard
                      key={item?.id}
                      name={item?.name}
                      status={item?.status}
                      position={item?.draftPosition}
                      score={item?.score}
                      logo={item?.logo_url}
                      id={item?.id}
                      eliminated
                      isMe={item?.userId === userData?.id}
                      eliminatedWeek={item?.eliminatedAt}
                      leagueStatus={data?.league?.status}
                      faab={item?.faab_balance}
                      user={data?.league?.user?.find(
                        (user: any) => user?.id === item?.userId
                      )}
                      teamScore={item?.teamScore}
                      latestScore={item?.latestScore}
                      week={week}
                      isAdmin={isAdmin}
                      leagueId={id}
                    />
                  ))
                : null}
            </div>
          </div>
          {isJoined ? (
            <div className="w-[30%] flex flex-col gap-6">
              <LiveChat chatRoomId={data?.league?.chatRoom?.id} />
              <LeagueLogs logs={leagueCurrentWeek?.league?.leagueLog} />
            </div>
          ) : null}
        </div>
      </div>

      <div className="flex gap-4 flex-col p-4 xl:hidden">
        <div className="w-full flex flex-row justify-center items-center">
          <img
            src={isValidImg(data?.league?.logoUrl) || demo_logo}
            alt="CutThroat"
            className="w-[100px] h-[100px] rounded-[50px] mr-6"
          />
          <div>
            <h2 className="font-absoluteEmpire text-[30px] leading-none">
              {data?.league?.name}
            </h2>
            <p className="text-text-grey">{data?.league?.description}</p>
          </div>
        </div>
        {userData?.id && isAdmin && (
          <>
            <div className="w-full flex   justify-center gap-4">
              <AddNewLeagueModal isEdit />
              {data?.league?.status === "registration" && (
                <CloseRegistrationModal />
              )}
            </div>
            <div className="w-full flex   justify-center gap-4">
              {data?.league?.status === "registration" && <LaunchDraftModal />}
              <DeleteLeagueModal />
            </div>
          </>
        )}
        <div className="w-full flex   justify-center gap-4">
          {data?.league?.status === "registration" && isAdmin && (
            <InviteUsersModal leagueId={id} />
          )}
          {!isJoined && !isAdmin && data?.league?.status === "registration" && (
            <LeagueRegistrationModal league={data?.league} />
          )}
          {isJoined ? (
            <Button
              className="rounded-[8px] w-full px-4 !h-10"
              onClick={() => navigate(`/draft/${data?.league?.draft?.[0]?.id}`)}
            >
              {data?.league?.draft?.[0]?.status !== "scheduled"
                ? "Draft Results"
                : " Go To Draft"}
            </Button>
          ) : null}
        </div>
        <div className="mt-[24px] flex flex-col gap-6">
          <div className="w-full flex   justify-center gap-4">
            <CustomBox
              title="League Status"
              data={data?.league?.status}
              className="capitalize"
            />
            <CustomBox
              title={
                data?.league?.status !== "registration"
                  ? "Current Progress"
                  : "Creation Date"
              }
              data={
                data?.league?.status !== "registration"
                  ? `Week ${currentLeagueWeek || "-"}`
                  : moment(data?.league?.createdAt).format("LL")
              }
            />
          </div>
          <div className="w-full flex   justify-center gap-4">
            <CustomBox
              title="Entry Fee"
              data={`$${handleNumber(data?.league?.entryFee ?? 0)} per team`}
            />
          </div>
          <div
            className={
              "max-w-full w-full min-h-[88px] bg-card-bgGreyDark rounded-[12px] border-[1px] border-modal-modalStroke py-[15px] px-6 flex justify-between gap-6"
            }
          >
            <div>
              <p className="text-text-subGrey font-gilroyMedium text-nowrap">
                1st Place Prize
              </p>
              <p className="font-gilroyBold text-[16px] text-nowrap xl:text-[20px] text-nowrap mt-6">
                {`$${handleNumber(data?.league?.prizeFundFirstPlace) ?? 0}`}
              </p>
            </div>
            <div>
              <p className="text-text-subGrey font-gilroyMedium text-nowrap">
                2nd Place Prize
              </p>
              <p className="font-gilroyBold text-[16px] text-nowrap xl:text-[20px] text-nowrap mt-6">
                {`$${handleNumber(data?.league?.prizeFundSecondPlace) ?? 0}`}
              </p>
            </div>
          </div>
          <div className="w-full flex   justify-center gap-4">
            <div className="min-w-unset w-full min-h-[88px] bg-card-bgGreyDark rounded-[12px] border-[1px] border-modal-modalStroke py-[15px] px-6 flex flex-row justify-between gap-4 xl:min-w-[350px]">
              <div className="flex flex-col justify-between">
                <p className="text-text-subGrey font-gilroyMedium">
                  {data?.league?.draft?.[0]?.status !== "scheduled"
                    ? "Number Of Teams Eliminated"
                    : "Draft Format"}
                </p>
                <p className="font-gilroyBold text-[16px] xl:text-[20px]">
                  {data?.league?.draft?.[0]?.status !== "scheduled"
                    ? data?.totalEliminatedTeams
                    : "Snake"}
                </p>
              </div>
              <div className="flex flex-col justify-between">
                <p className="text-text-subGrey font-gilroyMedium">
                  {data?.league?.draft?.[0]?.status !== "scheduled" &&
                  eliminationDate
                    ? "Next Elimination Date"
                    : "Draft Date"}
                </p>
                {isFetchingEliminationDate || isFetching ? (
                  <Oval
                    visible={isFetchingEliminationDate || isFetching}
                    height="20"
                    width="20"
                    color="#000000"
                    secondaryColor="#ffffff"
                  />
                ) : (
                  <p className="font-gilroyBold text-[16px] xl:text-[20px]">
                    {data?.league?.draft?.[0]?.status !== "scheduled" &&
                    eliminationDate
                      ? formatDateTimeInTimeZone(eliminationDate, "MMM DD")
                      : formatDateTimeInTimeZone(
                          data?.league?.draftDateTime,
                          "MMM DD"
                        )}
                  </p>
                )}
              </div>
              <div className="flex flex-col justify-between">
                <p className="text-text-subGrey font-gilroyMedium">Time</p>
                {isFetchingEliminationDate || isFetching ? (
                  <Oval
                    visible={isFetchingEliminationDate || isFetching}
                    height="20"
                    width="20"
                    color="#000000"
                    secondaryColor="#ffffff"
                  />
                ) : (
                  <p className="font-gilroyBold text-[16px] xl:text-[20px]">
                    {data?.league?.draft?.[0]?.status !== "scheduled" &&
                    eliminationDate
                      ? formatDateTimeInTimeZone(eliminationDate, "LT")
                      : formatDateTimeInTimeZone(
                          data?.league?.draftDateTime,
                          "LT"
                        )}{" "}
                    {getTimezoneAbbr()}
                  </p>
                )}
              </div>
            </div>
          </div>
          <CustomBox
            title="Teams"
            data={`${data?.league?.team?.length ?? 0} / ${
              data?.league?.numberOfTeams ?? 0
            }`}
            className="max-w-full xl:!max-w-[110px]"
          />
        </div>
        <div className="flex flex-col mt-[32px]">
          <div className="relative px-6 py-4 bg-filterContainer border-[1px] border-modal-modalStroke rounded-t-[12px] flex flex-row justify-between items-center">
            <p className="font-gilroyBold">Teams</p>
            {data?.league?.status !== "registration" && (
              <div className="flex overflow-x-scroll ml-4 gap-2 scrollbar-hide">
                {weekSlots?.map((item: number) => (
                  <button
                    key={item}
                    disabled={item + 1 < data?.league?.createdAtWeek}
                    className={clsx(
                      "cursor-pointer bg-card-bgGreyLight rounded-[8px] w-auto px-4 py-3 text-nowrap hover:bg-gradient-to-br hover:from-card-bgHover-1 hover:to-card-bgHover-2",
                      (week ? week : currentLeagueWeek) === item + 1 &&
                        "bg-gradient-to-br from-card-bgHover-1 to-card-bgHover-2",
                      item + 1 < data?.league?.createdAtWeek && "opacity-50"
                    )}
                    onClick={() => setWeek(item + 1)}
                  >
                    <p
                      className={clsx(
                        "text-[12px] font-gilroyBold",
                        currentLeagueWeek === item + 1 && "text-green-1"
                      )}
                    >
                      Week {item + 1}
                    </p>
                  </button>
                ))}
                {prjSlots?.map((item: number) => (
                  <button
                    key={item}
                    disabled={currentLeagueWeek + item + 1 > currentLeagueWeek}
                    className={clsx(
                      "cursor-pointer bg-card-bgGreyLight rounded-[8px] w-auto px-4 py-3 text-nowrap hover:bg-gradient-to-br hover:from-card-bgHover-1 hover:to-card-bgHover-2",
                      (week ? week : currentLeagueWeek) ===
                        currentLeagueWeek + item + 1 &&
                        "bg-gradient-to-br from-card-bgHover-1 to-card-bgHover-2",
                      currentLeagueWeek + item + 1 > currentLeagueWeek &&
                        "opacity-50"
                    )}
                    onClick={() => setWeek(item + 1)}
                  >
                    <p className="text-[12px] font-gilroyBold">
                      Week {currentLeagueWeek + item + 1}
                    </p>
                  </button>
                ))}
              </div>
            )}

            {isAdmin && <MoveTeamsModal />}
          </div>
          <div className="flex flex-row flex-wrap justify-center bg-card-bgGreyDark rounded-b-[12px] border-[1px] border-modal-modalStroke p-6 overflow-hidden gap-4 w-[100%] xl:justify-between">
            {(teamsAtRisk?.length || eliminatedTeams?.length) &&
            data?.league?.team?.length
              ? regularTeams
                  ?.sort(
                    (a: any, b: any) =>
                      Number(b?.latestScore) - Number(a?.latestScore)
                  )
                  ?.map((item: any) => (
                    <TeamCard
                      key={item?.id}
                      name={item?.name}
                      status={item?.status}
                      position={item?.draftPosition}
                      score={item?.score}
                      logo={item?.logo_url}
                      id={item?.id}
                      isMe={item?.userId === userData?.id}
                      eliminatedWeek={item?.eliminatedAt}
                      leagueStatus={data?.league?.status}
                      faab={item?.faab_balance}
                      user={getTeamOwner(item?.userId)}
                      teamScore={item?.teamScore}
                      latestScore={item?.latestScore}
                      week={week}
                      isAdmin={isAdmin}
                      leagueId={id}
                    />
                  ))
              : data?.league?.team
                  ?.sort(
                    (a: any, b: any) =>
                      Number(b?.latestScore) - Number(a?.latestScore)
                  )
                  ?.map((item: any) => (
                    <TeamCard
                      key={item?.id}
                      name={item?.name}
                      status={item?.status}
                      position={item?.draftPosition}
                      score={item?.score}
                      logo={item?.logo_url}
                      id={item?.id}
                      isMe={item?.userId === userData?.id}
                      eliminatedWeek={item?.eliminatedAt}
                      leagueStatus={data?.league?.status}
                      faab={item?.faab_balance}
                      user={getTeamOwner(item?.userId)}
                      teamScore={item?.teamScore}
                      latestScore={item?.latestScore}
                      week={week}
                      isAdmin={isAdmin}
                      leagueId={id}
                    />
                  ))}
            {data?.league?.numberOfTeams > data?.league?.team?.length &&
            !inProgress &&
            !completed
              ? [
                  ...Array(
                    data?.league?.numberOfTeams - data?.league?.team?.length
                  ).keys(),
                ]?.map((_item, index) => <OpenSlot key={index} />)
              : null}
            {orangeZoneTeams?.length ? (
              <div className="flex w-full gap-4 items-center">
                <div className="h-1 w-full rounded-[4px] bg-gradient-to-br from-orange-4 to-orange-1" />
                <div className="flex flex-col justify-center items-center">
                  <p className="text-orange-1 font-gilroyBold text-center text-nowrap">
                    Danger zone
                  </p>
                </div>
                <div className="h-1 w-full rounded-[4px] bg-gradient-to-br from-orange-1 to-orange-4" />
              </div>
            ) : null}
            {orangeZoneTeams?.length
              ? orangeZoneTeams
                  ?.sort(
                    (a: any, b: any) =>
                      Number(b?.latestScore) - Number(a?.latestScore)
                  )
                  ?.map((item: any) => (
                    <TeamCard
                      key={item?.id}
                      name={item?.name}
                      status={item?.status}
                      position={item?.draftPosition}
                      score={item?.score}
                      logo={item?.logo_url}
                      id={item?.id}
                      atOrangeZone
                      isMe={item?.userId === userData?.id}
                      eliminatedWeek={item?.eliminatedAt}
                      leagueStatus={data?.league?.status}
                      faab={item?.faab_balance}
                      user={getTeamOwner(item?.userId)}
                      teamScore={item?.teamScore}
                      latestScore={item?.latestScore}
                      week={week}
                      isAdmin={isAdmin}
                      leagueId={id}
                    />
                  ))
              : null}
            {teamsAtRisk?.length || eliminatedTeams?.length ? (
              <div className="flex w-full gap-4 items-center">
                <div className="h-1 w-full rounded-[4px] bg-gradient-to-br from-red-bgGradient-1 to-red-bgGradient-2" />
                <div className="flex flex-col justify-center items-center">
                  <p className="text-text-red font-gilroyBold">Risk zone</p>
                  <p className="text-text-red font-gilroyMedium text-xs text-nowrap">
                    (Eliminated —{" "}
                    {formatDateTimeInTimeZone(eliminationDate, "ll")})
                  </p>
                </div>
                <div className="h-1 w-full rounded-[4px] bg-gradient-to-br from-red-bgGradient-1 to-red-bgGradient-2" />
              </div>
            ) : null}
            {teamsAtRisk?.length
              ? teamsAtRisk
                  ?.sort(
                    (a: any, b: any) =>
                      Number(b?.latestScore) - Number(a?.latestScore)
                  )
                  ?.map((item: any) => (
                    <TeamCard
                      key={item?.id}
                      name={item?.name}
                      status={item?.status}
                      position={item?.draftPosition}
                      score={item?.score}
                      logo={item?.logo_url}
                      id={item?.id}
                      atRisk
                      isMe={item?.userId === userData?.id}
                      eliminatedWeek={item?.eliminatedAt}
                      leagueStatus={data?.league?.status}
                      faab={item?.faab_balance}
                      user={getTeamOwner(item?.userId)}
                      teamScore={item?.teamScore}
                      latestScore={item?.latestScore}
                      week={week}
                      isAdmin={isAdmin}
                      leagueId={id}
                    />
                  ))
              : null}
            {eliminatedTeams?.length
              ? eliminatedTeams?.map((item: any) => (
                  <TeamCard
                    key={item?.id}
                    name={item?.name}
                    status={item?.status}
                    position={item?.draftPosition}
                    score={item?.score}
                    logo={item?.logo_url}
                    id={item?.id}
                    eliminated
                    isMe={item?.userId === userData?.id}
                    eliminatedWeek={item?.eliminatedAt}
                    leagueStatus={data?.league?.status}
                    faab={item?.faab_balance}
                    user={getTeamOwner(item?.userId)}
                    teamScore={item?.teamScore}
                    latestScore={item?.latestScore}
                    week={week}
                    isAdmin={isAdmin}
                    leagueId={id}
                  />
                ))
              : null}
          </div>
          {isJoined ? (
            <div className="mt-6 flex flex-col gap-6">
              <LeagueLogs logs={leagueCurrentWeek?.league?.leagueLog} />
              <LiveChat chatRoomId={data?.league?.chatRoom?.id} />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default League;
