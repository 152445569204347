import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { DRAFT_QUERY_KEY } from "./draftQueriesKeys";
import { DraftServices } from "../../services/apis/Draft/DraftServices";
import useAuthStore from "../../stores/auth/auth";

export const useFetchDraft = (id: string) => {
  const queryResult = useQuery<any>({
    queryKey: [DRAFT_QUERY_KEY.FETCH_DRAFT],
    queryFn: () => DraftServices.getDraftDetails(id),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!id,
    refetchOnReconnect: true,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useMakePick = () => {
  return useMutation({
    mutationFn: DraftServices.makePick,
  });
};

export const useFetchTeamQueue = (id: string) => {
  const queryResult = useQuery({
    queryKey: [DRAFT_QUERY_KEY.FETCH_TEAM_QUEUE],
    queryFn: () => DraftServices.getTeamQueue(id),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!id,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useAddToQueue = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: DraftServices.addPlayerToQueue,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [DRAFT_QUERY_KEY.FETCH_TEAM_QUEUE],
      });
    },
  });
};

export const useRemoveFromQueue = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: DraftServices.removePlayerFromQueue,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [DRAFT_QUERY_KEY.FETCH_TEAM_QUEUE],
      });
    },
  });
};

export const useRemoveAllQueue = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: DraftServices.removeAllQueue,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [DRAFT_QUERY_KEY.FETCH_TEAM_QUEUE],
      });
    },
  });
};

export const useChangeQueuePriority = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: DraftServices.changeQueuePlayerPriority,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [DRAFT_QUERY_KEY.FETCH_TEAM_QUEUE],
      });
    },
  });
};

export const useFetchDraftPlayers = (id: string, params: any) => {
  const { allDraftPlayers } = useAuthStore(["allDraftPlayers"]);
  const queryResult = useQuery<any>({
    queryKey: [DRAFT_QUERY_KEY.FETCH_DRAFT_PLAYERS],
    queryFn: () => DraftServices.getAllDraftPlayers(id, params),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!id && !!(params?.offset <= allDraftPlayers?.players?.length),
    placeholderData: keepPreviousData,
  });

  return queryResult;
};
