import {
  formatAsFriendlyPastDate,
  groupMessagesByDay,
} from "../../../../utils/helpers";
import clsx from "clsx";
import { BidHistoryModal } from "./BidsHistory";
import { useEffect, useRef } from "react";

const LeagueLogs = ({ logs }: { logs: any }) => {
  const logsEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    logsEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [logs]);
  return (
    <div className="w-full ">
      <div className="px-6 py-4 bg-filterContainer border-t-[1px] border-x-[1px] border-modal-modalStroke rounded-t-[12px] flex flex-row justify-between items-center">
        <p className="font-gilroyBold">Transactions</p>
      </div>
      <div className="w-full border-b-[1px] border-x-[1px] border-modal-modalStroke h-[450px] bg-card-bgGreyDark rounded-b-[12px] overflow-y-scroll scrollbar flex flex-col p-6">
        {logs?.length ? (
          Object.keys(groupMessagesByDay(logs))
            ?.sort(
              (a: any, b: any) =>
                new Date(b)?.valueOf() - new Date(a)?.valueOf()
            )
            ?.map((date, i) => {
              const group = groupMessagesByDay(logs)[date];

              return (
                <div key={i}>
                  <div className="flex gap-4 my-1">
                    <p className="text-text-grey font-gilroyMedium text-nowrap">
                      {formatAsFriendlyPastDate(
                        new Date(group[0].createdAt)
                      )?.toString()}
                    </p>
                  </div>
                  {group
                    ?.sort(
                      (a: any, b: any) =>
                        new Date(b?.createdAt)?.valueOf() -
                        new Date(a?.createdAt)?.valueOf()
                    )
                    ?.map((item: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className={clsx(
                            "flex justify-between items-center my-2",
                            item?.type === "bidAction" && "cursor-pointer"
                          )}
                        >
                          <BidHistoryModal log={item} />
                        </div>
                      );
                    })}
                </div>
              );
            })
        ) : (
          <p className="font-gilroyMedium text-text-grey">No transactions</p>
        )}
      </div>
    </div>
  );
};

export default LeagueLogs;
