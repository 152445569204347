import clsx from "clsx";
import React from "react";

const CustomBox = ({
  title,
  data,
  className,
  onClick,
  showCustomComponent = false,
  customComponent,
}: {
  title: string;
  data: string;
  className?: string;
  onClick?: () => void;
  showCustomComponent?: boolean;
  customComponent?: React.ReactNode;
}) => {
  return (
    <div
      className={clsx(
        "w-full min-h-[88px] bg-card-bgGreyDark rounded-[12px] border-[1px] border-modal-modalStroke py-[15px] px-5 flex flex-col justify-between",
        className
      )}
    >
      <div className="flex flex-row items-center">
        <p className="text-text-subGrey font-gilroyMedium">{title}</p>
        {showCustomComponent && customComponent}
      </div>

      <p
        onClick={onClick}
        className={clsx(
          "font-gilroyBold text-[16px] text-nowrap xl:text-[20px] text-wrap",
          onClick && "cursor-pointer"
        )}
      >
        {data}
      </p>
    </div>
  );
};

export default CustomBox;
