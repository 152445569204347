import { Pagination } from "../Pagination/Pagination";
import { DraftTable } from "../Table/DraftTable";
import { PlayersTable } from "../Table/PlayersTable";
import { Table } from "../Table/Table";
import { TableCardsListingPropsType } from "./type";

const TableCardsListing = ({
  columns,
  dataSource,
  className,
  emptyState,
  onClickRow,
  size,
  tableClassName,
  loading,
  containerClass,
  overrideTableContainerClass,
  isPlayers,
  isDraft,
  paginationData,
}: TableCardsListingPropsType) => {
  return (
    <div>
      {isPlayers && (
        <PlayersTable
          loading={loading}
          size={size}
          columns={columns}
          dataSource={dataSource}
          tableClassName={tableClassName}
          containerClass={`${containerClass ?? ""} ${
            className ?? ""
          }`}
          onClickRow={onClickRow}
          emptyState={emptyState}
          overrideTableContainerClass={overrideTableContainerClass}
          footer={
            paginationData && (
              <Pagination {...paginationData} loading={loading} />
            )
          }
        />
      )}
      {isDraft && (
        <DraftTable
          loading={loading}
          size={size}
          columns={columns}
          dataSource={dataSource}
          tableClassName={tableClassName}
          containerClass={`${containerClass ?? ""} ${
            className ?? ""
          }`}
          onClickRow={onClickRow}
          emptyState={emptyState}
          overrideTableContainerClass={overrideTableContainerClass}
        />
      )}
      {!isPlayers && !isDraft && (
        <Table
          loading={loading}
          size={size}
          columns={columns}
          dataSource={dataSource}
          tableClassName={tableClassName}
          containerClass={`${containerClass ?? ""} ${
            className ?? ""
          }`}
          onClickRow={onClickRow}
          emptyState={emptyState}
          overrideTableContainerClass={overrideTableContainerClass}
          footer={
            paginationData && (
              <Pagination {...paginationData} loading={loading} />
            )
          }
        />
      )}
    </div>
  );
};

export default TableCardsListing;
