import { Button } from "../../../components";
import { ReactComponent as BackLeft } from "../../../assets/icons/arrow-left.svg";
import { ReactComponent as Dynamic } from "../../../assets/icons/group.svg";
import { TeamCard } from "./components/TeamCard";
import TableCardsListing from "../../../components/TableCardsListing/TableCardsListing";
import useDraftHook from "./hooks/useDraft";
import PlayersList from "./components/PlayersList";
import { DraftServices } from "../../../services/apis/Draft/DraftServices";
import { toast } from "react-toastify";
import clsx from "clsx";
import { formatDateTimeInTimeZone } from "../../../utils/helpers";
import { Oval } from "react-loader-spinner";
import Timer from "./components/Timer";
import RoundTimer from "./components/RoundTimer";

const Draft = () => {
  const {
    columns,
    data,
    isFetching,
    isViewPlayers,
    setIsViewPlayers,
    slotsWithIndexs,
    myTeam,
    userData,
    navigate,
    isAdmin,
    league,
    isFetchingAllPlayers,
    refetch,
    isDraftFull,
  } = useDraftHook();

  return (
    <>
      <div
        className={clsx(
          "overflow-x-hidden h-full",
          isViewPlayers && "!h-[150vh]"
        )}
      >
        <div className="px-10 pb-6 w-full mt-3">
          <div className="flex flex-col gap-4 md:hidden">
            <div className="flex justify-between items-center gap-4 relative ">
              <div
                onClick={() => navigate(-1)}
                className="flex flex-row justify-center items-center cursor-pointer rounded-[8px] bg-card-bgGreyDark p-3"
              >
                <BackLeft />
              </div>
              <div className="md:hidden">
                {data?.currentDraft?.status === "active" &&
                data?.currentDraft?.pool?.timeout_number &&
                !isDraftFull ? (
                  <RoundTimer
                    timeout={data?.currentDraft?.pool?.timeout_number}
                    refetch={refetch}
                    isSelectedPlayers={Boolean(
                      data?.currentDraft?.selectedPlayer?.length
                    )}
                  />
                ) : (
                  isDraftFull && (
                    <p className="text-[32px] font-absoluteEmpire">Completed</p>
                  )
                )}
              </div>
              <div className="bg-button-bgSolid rounded-[8px] p-3">
                <Dynamic />
              </div>
            </div>
            <div className="flex items-center justify-between">
              {data?.currentDraft?.status !== "finished" && (
                <Button
                  mode="primary"
                  onClick={() => {
                    setIsViewPlayers(!isViewPlayers);
                  }}
                  className="rounded-[8px] w-[50%] px-4 !h-10"
                >
                  {isViewPlayers ? "Hide Players" : "View Players"}
                </Button>
              )}
              {isAdmin && (
                <Button
                  mode="secondary"
                  //TODO: remove end draft button
                  onClick={async () => {
                    try {
                      await DraftServices.endDraft(
                        data?.currentDraft?.leagueId
                      );
                    } catch (error: any) {
                      toast.error(error?.message);
                    }
                  }}
                  className="rounded-[8px] w-[50%] px-4 !h-10 ml-2"
                >
                  End Draft (Dev)
                </Button>
              )}
            </div>
          </div>
          <div className="hidden justify-between items-center gap-4 relative md:flex">
            <div
              onClick={() => navigate(-1)}
              className="flex flex-row justify-center items-center cursor-pointer"
            >
              <BackLeft />
              <span className="font-gilroyMedium text-base ml-1">Back</span>
            </div>
            <div className="">
              {data?.currentDraft?.status === "active" &&
              data?.currentDraft?.pool?.timeout_number &&
              !isDraftFull ? (
                <RoundTimer
                  timeout={data?.currentDraft?.pool?.timeout_number}
                  refetch={refetch}
                  isSelectedPlayers={Boolean(
                    data?.currentDraft?.selectedPlayer?.length
                  )}
                />
              ) : (
                isDraftFull && (
                  <p className="text-[32px] font-absoluteEmpire">Completed</p>
                )
              )}
            </div>
            <div className="items-center hidden md:flex">
              <p className="text-[24px] mr-6 font-gilroyBold">
                {data?.currentDraft?.pool?.current_userId === userData?.id &&
                !isDraftFull
                  ? "It’s your turn!"
                  : ""}
              </p>
              {data?.currentDraft?.status !== "finished" && (
                <Button
                  mode="primary"
                  onClick={() => {
                    setIsViewPlayers(!isViewPlayers);
                  }}
                  className="rounded-[8px] w-auto px-4 !h-10"
                >
                  {isViewPlayers ? "Hide Players" : "View Players"}
                </Button>
              )}
              {isAdmin && (
                <Button
                  mode="secondary"
                  //TODO: remove end draft button
                  onClick={async () => {
                    try {
                      await DraftServices.endDraft(
                        data?.currentDraft?.leagueId
                      );
                    } catch (error: any) {
                      toast.error(error?.message);
                    }
                  }}
                  className="rounded-[8px] w-auto px-4 !h-10 ml-2"
                >
                  End Draft (Dev)
                </Button>
              )}
            </div>
          </div>
        </div>

        <div className="px-10 pb-6 overflow-x-auto relative scrollbar">
          {data?.currentDraft?.status === "scheduled" && (
            <div className="absolute w-full h-full inset-0 bg-modal-bgModal backdrop-blur-sm z-50 p-10 flex flex-col justify-center items-center self-center">
              <div className="flex justify-center items-center">
                <p className="font-absoluteEmpire text-[32px] text-center mr-2">
                  Start drafting at{" "}
                </p>
                {isFetching ? (
                  <Oval
                    visible={isFetching}
                    height="20"
                    width="20"
                    color="#000000"
                    secondaryColor="#ffffff"
                  />
                ) : (
                  <span className="text-text-red font-absoluteEmpire text-[32px] text-center">
                    {league?.league?.draftDateTime
                      ? formatDateTimeInTimeZone(
                          league?.league?.draftDateTime,
                          "lll"
                        )
                      : ""}
                  </span>
                )}
              </div>
              {league?.league?.draftDateTime && (
                <Timer draftTime={league?.league?.draftDateTime} />
              )}
            </div>
          )}
          {data?.currentDraft?.status === "finished" &&
            (isFetching ? (
              <Oval
                visible={isFetching}
                height="20"
                width="20"
                color="#000000"
                secondaryColor="#ffffff"
              />
            ) : (
              <p className="font-absoluteEmpire text-[32px] text-center">
                Draft finished
              </p>
            ))}
          <div className="mt-6 flex-col justify-start items-center gap-1">
            <div className={`flex flex-row gap-3`}>
              {data?.teamsInDraft
                ?.sort((a: any, b: any) => a?.draftPosition - b?.draftPosition)
                .map((item: any) => (
                  <TeamCard
                    key={item?.id}
                    name={item?.name}
                    userId={item?.userId}
                    logo={item?.logo_url}
                  />
                ))}
            </div>
            <TableCardsListing
              columns={columns}
              onClickRow={() => {}}
              dataSource={slotsWithIndexs ?? []}
              overrideTableContainerClass="block"
              containerClass="w-fit"
              loading={isFetching}
              isDraft
            />
          </div>
        </div>

        {isViewPlayers && (
          <PlayersList
            isFetchingAllPlayers={isFetchingAllPlayers}
            canMakePick={
              data?.currentDraft?.pool?.current_userId === userData?.id
            }
            teamId={myTeam?.id}
            draft={data?.currentDraft}
          />
        )}
      </div>
    </>
  );
};

export default Draft;
