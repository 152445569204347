import { Button, Modal, useModal } from "../../../../components";
import { ReactComponent as Plus } from "../../../../assets/icons/plus-white-icon.svg";
import { ReactComponent as Minus } from "../../../../assets/icons/minus-icon.svg";
import player_demo from "../../../../assets/images/player_demo.png";
import usePlayerDetailsHook from "../hooks/usePlayerDetails";
import clsx from "clsx";
import TableStatisticListing from "../../../../components/TableCardsListing/TableStatisticListing";
import { isValidImg } from "../../../../utils/helpers";
import { Oval } from "react-loader-spinner";

export const PlayerDetailsModal = ({
  id,
  children,
  enabled = true,
  leagueId,
  teamId,
}: {
  id: string;
  children: any;
  enabled?: boolean;
  leagueId: string;
  teamId: string;
}) => {
  const addContent = ({ closeModal }: { closeModal: () => void }) => (
    <PlayerDetails
      id={id}
      closeModal={closeModal}
      leagueId={leagueId}
      teamId={teamId}
    />
  );
  const { modalControl, openModal } = useModal({
    title: "",
    content: addContent,
    size: "full",
    showCloseIcon: true,
  });
  return (
    <>
      <Modal {...modalControl} />
      <div onClick={enabled ? openModal : () => {}}>{children}</div>
    </>
  );
};

const PlayerDetails = ({
  closeModal,
  id,
  leagueId,
  teamId,
}: {
  closeModal?: () => void;
  id: string;
  leagueId: string;
  teamId: string;
}) => {
  const {
    data,
    columnsFantasy,
    isFetching,
    columnsPass,
    columnsRushing,
    activeTab,
    setActiveTab,
    bid,
    setBid,
    BID_STEP,
    columnsReceiving,
    week,
    setWeek,
    weeklyStats,
    isFetchingStats,
    weekSlots,
    isError,
    currentWeek,
    prjSlots,
    currentPlayer,
    league,
  } = usePlayerDetailsHook({ closeModal, id, leagueId, teamId });

  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center">
        <img
          src={isValidImg(data?.player?.officialImageSrc) || player_demo}
          alt="CutThroat"
          className="w-[100px] h-[100px] rounded-[30px] mr-2 md:w-[160px] md:h-[160px] md:mr-6"
        />
        <div className="hidden flex-col gap-4 md:flex">
          <p className="text-[32px] font-absoluteEmpire">
            {data?.player?.firstName} {data?.player?.lastName}
          </p>
          <div className="h-[40px] rounded-[8px] bg-modal-inputBg w-[50%] flex items-center">
            <div className="w-[40px] h-[40px] rounded-[8px] bg-gradient-to-br from-red-light-1 to-red-light-2 flex justify-center items-center">
              <p className="font-gilroyMedium">
                {data?.player?.primaryPosition}
              </p>
            </div>
            <p className="ml-3 text-base font-gilroyMedium">
              {data?.player?.currentTeam?.abbreviation || ""} #
              {data?.player?.jerseyNumber}
            </p>
          </div>
          <div className="flex">
            <div className="flex flex-col justify-center items-center pr-3">
              <p className="text-text-subGrey font-gilroyMedium">Age</p>
              <p className="text-base font-gilroyBold mt-1">
                {data?.player?.age}
              </p>
            </div>
            <div className="w-[1px] h-[48px] bg-modal-modalStroke" />
            <div className="flex flex-col justify-center items-center px-3">
              <p className="text-text-subGrey font-gilroyMedium">Height</p>
              <p className="text-base font-gilroyBold mt-1">
                {data?.player?.height}
              </p>
            </div>
            <div className="w-[1px] h-[48px] bg-modal-modalStroke" />
            <div className="flex flex-col justify-center items-center px-3">
              <p className="text-text-subGrey font-gilroyMedium">Weight</p>
              <p className="text-base font-gilroyBold mt-1">
                {data?.player?.weight} lbs
              </p>
            </div>
            <div className="w-[1px] h-[48px] bg-modal-modalStroke" />
            <div className="flex flex-col justify-center items-center px-3">
              <p className="text-text-subGrey font-gilroyMedium">College</p>
              <p className="text-base font-gilroyBold mt-1">
                {data?.player?.college}
              </p>
            </div>
            <div className="w-[1px] h-[48px] bg-modal-modalStroke" />
          </div>
        </div>
        <p className="block text-[22px] font-absoluteEmpire md:hidden">
          {data?.player?.firstName} {data?.player?.lastName}
        </p>
      </div>
      <div className="block w-[100%] flex flex-col gap-3 md:hidden">
        <div className="h-[40px] rounded-[8px] bg-modal-inputBg w-[50%] flex items-center">
          <div className="w-[40px] h-[40px] rounded-[8px] bg-gradient-to-br from-red-light-1 to-red-light-2 flex justify-center items-center">
            <p className="font-gilroyMedium">{data?.player?.primaryPosition}</p>
          </div>
          <p className="ml-3 text-base font-gilroyMedium">
            {data?.player?.currentTeam?.abbreviation || ""} #
            {data?.player?.jerseyNumber}
          </p>
        </div>
        <div className="w-[100%] flex items-start mt-2">
          <div className="flex flex-col justify-center items-center pr-[7px]">
            <p className="text-text-subGrey font-gilroyMedium">Age</p>
            <p className="text-base font-gilroyBold mt-1">
              {data?.player?.age}
            </p>
          </div>
          <div className="w-[1px] h-[48px] bg-modal-modalStroke" />
          <div className="flex flex-col justify-center items-center px-[7px]">
            <p className="text-text-subGrey font-gilroyMedium">Height</p>
            <p className="text-base font-gilroyBold mt-1">
              {data?.player?.height}
            </p>
          </div>
          <div className="w-[1px] h-[48px] bg-modal-modalStroke" />
          <div className="flex flex-col justify-center items-center px-[7px]">
            <p className="text-text-subGrey font-gilroyMedium">Weight</p>
            <p className="text-base font-gilroyBold mt-1">
              {data?.player?.weight} lbs
            </p>
          </div>
          <div className="w-[1px] h-[48px] bg-modal-modalStroke" />
          <div className="flex flex-col justify-center items-center px-[7px]">
            <p className="text-text-subGrey font-gilroyMedium">College</p>
            <p className="text-base font-gilroyBold mt-1">
              {data?.player?.college}
            </p>
          </div>
          <div className="w-[1px] h-[48px] bg-modal-modalStroke" />
        </div>
      </div>

      <div className="mt-7">
        <div className="flex gap-6">
          <p
            onClick={() => setActiveTab(1)}
            className={clsx(
              "text-base text-text-subGrey cursor-pointer",
              activeTab === 1 && "text-text-white font-gilroyBold"
            )}
          >
            Game Logs
          </p>
          {data?.player?.currentInjury && (
            <p
              onClick={() => setActiveTab(2)}
              className={clsx(
                "text-base text-text-subGrey cursor-pointer",
                activeTab === 2 && "text-text-white font-gilroyBold"
              )}
            >
              Injuries
            </p>
          )}
        </div>
        <div className="flex justify-between items-center">
          {false && (
            <div className="flex items-center gap-2 mt-6">
              <Button
                leftIcon={<Minus />}
                mode="secondary"
                className="!h-[41px] !w-[40px]"
                onClick={() => {
                  if (bid > BID_STEP) {
                    setBid(bid - BID_STEP);
                  }
                }}
              >
                {" "}
              </Button>
              <div className="w-[80px] h-[48px] bg-modal-inputBg rounded-[8px] border-[2px] border-filterContainer px-3 py-3 flex justify-center items-center md:w-[120px] md:px-5">
                <p className="text-base text-text-subGrey font-gilroyBold">
                  ${bid}.00
                </p>
              </div>
              <Button
                leftIcon={<Plus />}
                mode="primary"
                className="!h-[41px] !w-[40px]"
                onClick={() => setBid(bid + BID_STEP)}
              >
                {" "}
              </Button>
              <Button
                mode="primary"
                className="!h-[48px] w-auto px-4 ml-2 rounded-[8px] md:ml-4 md:w-[100px]"
                onClick={() => {}}
              >
                Bid ${bid}
              </Button>
            </div>
          )}
        </div>
      </div>

      {activeTab === 1 && (
        <>
          {!isError ? (
            isFetchingStats ? (
              <Oval
                visible={isFetchingStats}
                height="20"
                width="20"
                color="#000000"
                secondaryColor="#ffffff"
              />
            ) : (
              <div className="overflow-x-auto">
                <div className="flex overflow-x-scroll gap-2 no-wrap scrollbar-hide">
                  {weekSlots?.map((item: number) => (
                    <button
                      key={item}
                      className={clsx(
                        "cursor-pointer bg-card-bgGreyLight rounded-[8px] w-auto px-4 py-3 text-nowrap hover:bg-gradient-to-br hover:from-card-bgHover-1 hover:to-card-bgHover-2",
                        (week ? week : currentWeek) === item + 1 &&
                          "bg-gradient-to-br from-card-bgHover-1 to-card-bgHover-2"
                      )}
                      onClick={() => setWeek(item + 1)}
                    >
                      <p className="text-[12px] font-gilroyBold">
                        {currentWeek === item + 1 && "Current "} Week {item + 1}
                      </p>
                    </button>
                  ))}
                  {prjSlots?.map((item: number) => (
                    <button
                      key={item}
                      className={clsx(
                        "cursor-pointer bg-card-bgGreyLight rounded-[8px] w-auto px-4 py-3 text-nowrap hover:bg-gradient-to-br hover:from-card-bgHover-1 hover:to-card-bgHover-2",
                        (week ? week : currentWeek) ===
                          currentWeek + item + 1 &&
                          "bg-gradient-to-br from-card-bgHover-1 to-card-bgHover-2"
                      )}
                      onClick={() => setWeek(currentWeek + item + 1)}
                    >
                      <p className="text-[12px] font-gilroyBold">
                        Week {currentWeek + item + 1} PRJ
                      </p>
                    </button>
                  ))}
                </div>
                <div className="flex justify-between my-6 min-w-[900px] max-w-[1600px]">
                  <div className="w-[30%]">
                    <div className="flex items-center">
                      <div className="w-full h-[1px] bg-text-red mr-4" />
                      <p className="text-xs text-text-red font-gilroyMedium">
                        Fantasy
                      </p>
                      <div className="w-full h-[1px] bg-text-red ml-4 mr-1" />
                    </div>
                    <TableStatisticListing
                      columns={columnsFantasy}
                      onClickRow={() => {}}
                      dataSource={
                        !isError
                          ? [
                              {
                                projectedStats: weeklyStats?.projectedStats,
                                stats: weeklyStats?.stats,
                                projectedFantasyPointsThisWeek: weeklyStats?.projectedFantasyPointsThisWeek,
                                fantasyPointsThisWeek: weeklyStats?.fantasyPointsThisWeek,
                                ADP: weeklyStats?.ADP,
                                game: weeklyStats?.game,
                                team: weeklyStats?.team,
                                playerData: currentPlayer,
                                week: week,
                                currentWeek: currentWeek,
                                isPPR:
                                  league?.league?.receivingOption === "PPR",
                              },
                            ]
                          : []
                      }
                      overrideTableContainerClass="!px-0 mr-1"
                      containerClass="border-0"
                      loading={isFetching}
                      isPlayers
                    />
                  </div>
                  <div className="w-[30%]">
                    <div className="flex items-center">
                      <div className="w-full h-[1px] bg-text-red mr-4" />
                      <p className="text-xs text-text-red font-gilroyMedium">
                        Passing
                      </p>
                      <div className="w-full h-[1px] bg-text-red ml-4 mr-1" />
                    </div>
                    <TableStatisticListing
                      columns={columnsPass}
                      onClickRow={() => {}}
                      dataSource={
                        !isError
                          ? [
                              {
                                projectedStats: weeklyStats?.projectedStats,
                                stats: weeklyStats?.stats,
                                projectedPoints: weeklyStats?.projectedPoints,
                                ADP: weeklyStats?.ADP,
                                playerData: currentPlayer,
                                week: week,
                                currentWeek: currentWeek,
                                isPPR: league?.receivingOption === "PPR",
                              },
                            ]
                          : []
                      }
                      overrideTableContainerClass="!px-0 mr-1"
                      containerClass="border-0"
                      loading={isFetching}
                      isPlayers
                    />
                  </div>
                  <div className="w-[20%]">
                    <div className="flex items-center">
                      <div className="w-full h-[1px] bg-text-red mr-4" />
                      <p className="text-xs text-text-red font-gilroyMedium">
                        Rushing
                      </p>
                      <div className="w-full h-[1px] bg-text-red ml-4 mr-1" />
                    </div>
                    <TableStatisticListing
                      columns={columnsRushing}
                      onClickRow={() => {}}
                      dataSource={
                        !isError
                          ? [
                              {
                                projectedStats: weeklyStats?.projectedStats,
                                stats: weeklyStats?.stats,
                                projectedPoints: weeklyStats?.projectedPoints,
                                ADP: weeklyStats?.ADP,
                                playerData: currentPlayer,
                                week: week,
                                currentWeek: currentWeek,
                                isPPR: league?.receivingOption === "PPR",
                              },
                            ]
                          : []
                      }
                      overrideTableContainerClass="!px-0 mr-1"
                      containerClass="border-0"
                      loading={isFetching}
                      isPlayers
                    />
                  </div>
                  <div className="w-[20%]">
                    <div className="flex items-center">
                      <div className="w-full h-[1px] bg-text-red mr-4" />
                      <p className="text-xs text-text-red font-gilroyMedium">
                        Receiving
                      </p>
                      <div className="w-full h-[1px] bg-text-red ml-4 mr-1" />
                    </div>
                    <TableStatisticListing
                      columns={columnsReceiving}
                      onClickRow={() => {}}
                      dataSource={
                        !isError
                          ? [
                              {
                                projectedStats: weeklyStats?.projectedStats,
                                stats: weeklyStats?.stats,
                                projectedPoints: weeklyStats?.projectedPoints,
                                ADP: weeklyStats?.ADP,
                                playerData: currentPlayer,
                                week: week,
                                currentWeek: currentWeek,
                                isPPR: league?.receivingOption === "PPR",
                              },
                            ]
                          : []
                      }
                      overrideTableContainerClass="!px-0 mr-1"
                      containerClass="border-0"
                      loading={isFetching}
                      isPlayers
                    />
                  </div>
                </div>
              </div>
            )
          ) : isFetchingStats ? (
            <Oval
              visible={isFetchingStats}
              height="20"
              width="20"
              color="#000000"
              secondaryColor="#ffffff"
            />
          ) : (
            <p className="text-text-subGrey font-gilroyMedium">No logs found</p>
          )}
        </>
      )}
      {activeTab === 2 && (
        <>
          {data?.player?.currentInjury ? (
            <p className="text-text-white font-gilroyBold my-6 text-left">
              Description: {data?.player?.currentInjury?.description}
            </p>
          ) : (
            <p className="text-text-subGrey font-gilroyMedium">
              No injuries found
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default PlayerDetailsModal;
