import { forwardRef, useState } from "react";

import { ReactComponent as EyeRegular } from "../../assets/icons/eye.svg";
import { ReactComponent as EyeOffRegular } from "../../assets/icons/close-eye.svg";
import clsx from "clsx";
import LabeledInput from "../../components/LabeledInput/LabeledInput";
import { LabeledInputPropsType } from "../../components/LabeledInput/type";

type LabeledPasswordPropsType = LabeledInputPropsType & {
  passwordForIndicator?: string;
};
const LabeledPasswordInput = forwardRef<
  HTMLInputElement,
  LabeledPasswordPropsType
>(
  (
    {
      label,
      error,
      labelClassName,
      errorClassName,
      containerClassName,
      inputContainerClassName,
      passwordForIndicator,
      ...rest
    }: LabeledPasswordPropsType,
    ref
  ) => {
    const [inputType, setInputType] = useState<"text" | "password">("password");

    return (
      <div className={clsx("flex flex-col gap-4 w-full", containerClassName)}>
        <LabeledInput
          label={label}
          error={error}
          labelClassName={labelClassName}
          errorClassName={errorClassName}
          {...rest}
          ref={ref}
          inputContainerClassName={inputContainerClassName}
          type={inputType}
          rightIcon={
            rest?.rightIcon ?? (
              <div
                onClick={() => {
                  setInputType((prev) =>
                    prev === "password" ? "text" : "password"
                  );
                }}
              >
                {inputType === "password" ? (
                  <EyeOffRegular
                    className={clsx(
                      "w-6 h-6 cursor-pointer text-neutral-white-4"
                    )}
                  />
                ) : (
                  <EyeRegular
                    className={clsx(
                      "w-6 h-6 cursor-pointer text-neutral-white-4"
                    )}
                  />
                )}
              </div>
            )
          }
        />
      </div>
    );
  }
);

export default LabeledPasswordInput;
