import { useParams } from "react-router-dom";
import { socket } from "../../../../socket";
import { useEffect, useState } from "react";
import { DraftServices } from "../../../../services/apis/Draft/DraftServices";

const useDraftCountdown = (
  currentTime?: number,
  refetch?: () => void,
  isSelectedPlayers?: boolean
) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [currentCountDownTime, setCountDown] = useState(currentTime);

  useEffect(() => {
    const getCurrentTime = async () => {
      try {
        const time = await DraftServices.getDraftCurrentTime(id!);
        setCountDown(time * 1000);
      } catch (error: any) {
        console.error(error);
      }
    };
    getCurrentTime();
  }, [id]);

  useEffect(() => {
    socket.emit("joinDraft", id);
    const handleDraftPick = (data: any) => {
      if (data?.draftId === id) {
        if (currentTime === 60000 || currentTime === 90000) {
          setCountDown(currentTime);
        } else if (
          (currentTime === 70000 || currentTime === 100000) &&
          isSelectedPlayers
        ) {
          setCountDown(currentTime - 10000);
        } else setCountDown(currentTime);
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 3000);
        refetch?.();
      }
    };
    socket.on("draft-pick", handleDraftPick);

    return () => {
      socket.off("draft-pick", handleDraftPick);
    };
  }, [id, isSelectedPlayers, currentTime]);

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      setCountDown(currentCountDownTime! - 1000);
    }, 1000);
    if (currentCountDownTime === 0) {
      clearInterval(interval);
      setLoading(true);
    }

    return () => clearInterval(interval);
  }, [currentCountDownTime, currentTime]);

  return {
    roundSeconds: getReturnValues(currentCountDownTime!),
    setCountDown,
    loading,
  };
};

const getReturnValues = (countDown: number) => {
  const roundSeconds = Math.floor(countDown / 1000);

  return roundSeconds;
};

export default useDraftCountdown;
