export const PLAYER_QUERY_KEY = {
  FETCH_PLAYERS: "FETCH_PLAYERS",
  FETCH_PLAYER: "FETCH_PLAYER",
  FETCH_PLAYER_STATS: "FETCH_PLAYER_STATS",
  FETCH_PLAYER_INJURES: "FETCH_PLAYER_INJURES",
  SEARCH_PLAYERS: "SEARCH_PLAYERS",
  FETCH_PLAYER_WEEKLY_STATS: "FETCH_PLAYER_WEEKLY_STATS",
  FETCH_TEAM_PLAYERS: "FETCH_TEAM_PLAYERS",
  FETCH_TEAM_PLAYERS_PRJ: "FETCH_TEAM_PLAYERS_PRJ",
};
