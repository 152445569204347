import { Button } from "../../../../components";
import { ReactComponent as Favorite } from "../../../../assets/icons/favorite-icon.svg";
import { ReactComponent as FavoriteFilled } from "../../../../assets/icons/favorite-filled-icon.svg";
import { ReactComponent as Arrow } from "../../../../assets/icons/arrowUp-icon.svg";
import { useEffect, useState } from "react";
import clsx from "clsx";
import demo_team_logo from "../../../../assets/images/demoTeamLogo.png";
import {
  useAddPlayerToTeam,
  useSearchPlayers,
} from "../../../../queries/playerQueries/playerQueries";
import { toast } from "react-toastify";
import MakeBidModal from "../components/MakeBid";
import {
  useFetchMyBids,
  useFetchPlayersOnWaiver,
  useIncreasePriority,
  usePriorityDecrease,
} from "../../../../queries/waiverQueries/waiverQueries";
import { Popup } from "../../../../components/Popup/Popup";
import useAuthStore from "../../../../stores/auth/auth";
import {
  formatDateTimeInTimeZone,
  isValidImg,
} from "../../../../utils/helpers";
import {
  useFetchEliminationDate,
  useFetchLeague,
} from "../../../../queries/leaguesQueries/leaguesQueries";
import useLoadPlayers from "../../../../pages/private/Draft/hooks/useLoadPlayers";
import PlayerDetailsModal from "../components/PlayerDetails";
import { Oval } from "react-loader-spinner";
import PickPlayerModal from "../components/PickPlayerModal";
import { useAddPlayerByAdmin } from "../../../../queries/adminQueries/adminQueries";

const BidPriorityRender = ({ ...data }) => {
  const { mutateAsync: increasePriority } = useIncreasePriority();
  const { mutateAsync: priorityDecrease } = usePriorityDecrease();
  const firstPlayer = data?.allPlayersForFilter?.[0];
  const lastPlayer =
    data?.allPlayersForFilter?.[data?.allPlayersForFilter?.length - 1];
  return (
    <div className="flex gap-2">
      <Button
        className={clsx(
          "!h-[44px] !w-[44px]",
          data?.bid?.playerId === firstPlayer?.player?.id?.toString() &&
            "bg-card-stroke opacity-50"
        )}
        mode="secondary"
        onClick={() =>
          data?.bid?.playerId !== firstPlayer?.player?.id?.toString() &&
          increasePriority({ bidId: data?.bid?.id })
        }
        leftIcon={<Arrow className="rotate-180" />}
      >
        {""}
      </Button>
      <Button
        className={clsx(
          "!h-[44px] !w-[44px]",
          lastPlayer?.player?.id?.toString() === data?.bid?.playerId &&
            "bg-card-stroke opacity-50"
        )}
        mode="secondary"
        onClick={() =>
          lastPlayer?.player?.id?.toString() !== data?.bid?.playerId &&
          priorityDecrease({ bidId: data?.bid?.id })
        }
        leftIcon={<Arrow />}
      >
        {""}
      </Button>
    </div>
  );
};

const RKRender = ({ ...data }) => {
  return (
    <div className="flex justify-between">
      <p className="font-gilroyBold text-nowrap">{data?.ADP || "-"}</p>
    </div>
  );
};

const PassATTRender = ({ ...data }) => {
  return (
    <div className="flex justify-center item-center p-2 bg-modal-inputBg rounded-[8px] max-w-[50px]">
      <p className="font-gilroyBold text-nowrap">
        {data?.stats?.passing?.passCompletions ?? "0"}/
        {data?.stats?.passing?.passAttempts ?? "0"}
      </p>
    </div>
  );
};
const PassYardsRender = ({ ...data }) => {
  return (
    <div className="flex justify-center item-center p-2 bg-modal-inputBg rounded-[8px] max-w-[50px]">
      <p className="font-gilroyBold text-nowrap">
        {data?.stats?.passing?.passYards ?? "0"}
      </p>
    </div>
  );
};

const PassTDsRender = ({ ...data }) => {
  return (
    <div className="flex justify-center item-center p-2 bg-modal-inputBg rounded-[8px] max-w-[50px]">
      <p className="font-gilroyBold text-nowrap">
        {data?.stats?.passing?.passTD ?? "0"}
      </p>
    </div>
  );
};

const InttrRender = ({ ...data }) => {
  return (
    <div className="flex justify-center item-center p-2 bg-modal-inputBg rounded-[8px] max-w-[50px]">
      <p className="font-gilroyBold text-nowrap">
        {data?.stats?.interceptions?.interceptions ?? "0"}
      </p>
    </div>
  );
};
const RushYardsRender = ({ ...data }) => {
  return (
    <div className="flex justify-center item-center p-2 bg-modal-inputBg rounded-[8px] max-w-[50px]">
      <p className="font-gilroyBold text-nowrap">
        {data?.stats?.rushing?.rushYards ?? "0"}
      </p>
    </div>
  );
};

const RushTDsRender = ({ ...data }) => {
  return (
    <div className="flex justify-center item-center p-2 bg-modal-inputBg rounded-[8px] max-w-[50px]">
      <p className="font-gilroyBold text-nowrap">
        {data?.stats?.rushing?.rushTD ?? "0"}
      </p>
    </div>
  );
};
const RecYardsRender = ({ ...data }) => {
  return (
    <div className="flex justify-center item-center p-2 bg-modal-inputBg rounded-[8px] max-w-[50px]">
      <p className="font-gilroyBold text-nowrap">
        {data?.stats?.receiving?.recYards ?? "0"}
      </p>
    </div>
  );
};

const RecTDsRender = ({ ...data }) => {
  return (
    <div className="flex justify-center item-center p-2 bg-modal-inputBg rounded-[8px] max-w-[50px]">
      <p className="font-gilroyBold text-nowrap">
        {data?.stats?.receiving?.recTD ?? "0"}
      </p>
    </div>
  );
};
const RecRender = ({ ...data }) => {
  return (
    <div className="flex justify-center item-center p-2 bg-modal-inputBg rounded-[8px] max-w-[50px]">
      <p className="font-gilroyBold text-nowrap">
        {data?.stats?.receiving?.receptions ?? "0"}
      </p>
    </div>
  );
};

const PlayerRender = ({ ...data }) => {
  const endDate = formatDateTimeInTimeZone(
    data?.waiver?.endTime || data?.eliminationDate,
    "lll"
  );
  return (
    <PlayerDetailsModal
      teamId={data?.teamId}
      id={data?.player?.id}
      leagueId={data?.leagueId}
    >
      <div className="flex justify-between">
        <div className="flex items-center gap-2">
          <img
            src={isValidImg(data?.player?.officialImageSrc) || demo_team_logo}
            alt="CutThroat"
            className="w-[30px] h-[30px] rounded-[50px] md:w-[40px] md:h-[40px]"
          />
          <div>
            <div className="flex flex-col gap-1">
              <div className="flex items-center gap-1">
                <p className="font-gilroyBold text-base">
                  {data?.player?.firstName} {data?.player?.lastName}
                </p>
                {data?.waiver && (
                  <Popup
                    icon={
                      <span className="ml-1 text-text-red font-gilroyBold">
                        W
                      </span>
                    }
                    data={
                      <>
                        <p className="text-base text-left">On waiver until</p>
                        <p className="text-base text-left text-nowrap">
                          {endDate}
                        </p>
                      </>
                    }
                  />
                )}
              </div>
              <p className="text-text-grey text-xs font-gilroyMedium">
                {data?.player?.primaryPosition}
              </p>
            </div>
          </div>
        </div>
      </div>
    </PlayerDetailsModal>
  );
};

const ActionsRender = ({ ...data }) => {
  const { mutateAsync, isPending } = useAddPlayerToTeam();
  const { mutateAsync: addPlayerByAdmin, isPending: isPendingAdd } =
    useAddPlayerByAdmin();
  const isFavorite = data?.savedPlayers?.find(
    (item: any) => item?.id === data?.player?.id
  );

  const addForFree = async () => {
    try {
      if (data?.isAdmin) {
        await addPlayerByAdmin({
          teamId: data?.teamData?.id,
          playerId: data?.player?.id,
        });
      } else {
        await mutateAsync({
          teamId: data?.teamData?.id,
          player_id: data?.player?.id,
        });
      }
      toast.success("Player added successfully!");
      data?.refetchTeam();
    } catch (error: any) {
      if (
        error?.response?.data?.error ===
        "Cannot read properties of undefined (reading 'player')"
      ) {
        toast.error("Too many requests, please try again in 30 seconds");
      } else {
        toast.error(error?.response?.data?.error);
      }
    }
  };

  const checkPlayerPosition = (position: string) => {
    if (position === "QB") {
      const player = data?.teamData?.player?.find(
        (item: any) => item?.position === position
      );
      return player ? true : false;
    } else if (position === "WR") {
      const players = data?.teamData?.player?.filter(
        (item: any) => item?.position === position
      );
      return players?.length > 2 ? true : false;
    } else if (position === "RB") {
      const players = data?.teamData?.player?.filter(
        (item: any) => item?.position === position
      );
      return players?.length > 2 ? true : false;
    } else if (position === "TE") {
      const players = data?.teamData?.player?.filter(
        (item: any) => item?.position === position
      );
      return players?.length > 2 ? true : false;
    }
  };

  return (
    <div className="flex gap-2">
      {!data?.isAdmin && (
        <Button
          mode="secondary"
          disabled={data?.teamData?.player?.find(
            (item: any) => item?.playerId === data?.player?.id
          )}
          onClick={() => data?.handleSavedPlayers(data?.player)}
          leftIcon={isFavorite ? <FavoriteFilled /> : <Favorite />}
          className="h-[40px] w-[40px] rounded-[8px]"
        >
          {""}
        </Button>
      )}
      {data?.bid && !data?.isAdmin ? (
        <MakeBidModal
          bid={data?.bid}
          team={data?.teamData}
          player={data?.player}
          availablePosition={checkPlayerPosition(data?.player?.primaryPosition)}
        />
      ) : data?.waiver && !data?.isAdmin ? (
        <MakeBidModal
          team={data?.teamData}
          player={data?.player}
          waiver={data?.waiver}
          availablePosition={checkPlayerPosition(data?.player?.primaryPosition)}
        />
      ) : (data?.teamData?.player?.length > 5 ||
          checkPlayerPosition(data?.player?.primaryPosition)) &&
        !data?.isAdmin ? (
        <PickPlayerModal
          players={data?.teamData?.player}
          currentPlayer={data?.player}
          isBid={false}
          refetchWaiver={data?.refetchWaiver}
        />
      ) : (
        <Button
          onClick={addForFree}
          leftIcon={
            <Oval
              visible={isPending || isPendingAdd}
              height="20"
              width="20"
              color="#000000"
              secondaryColor="#ffffff"
            />
          }
          disabled={
            isPending ||
            isPendingAdd ||
            data?.league?.status !== "inProgress" ||
            data?.teamData?.player?.length > 5 ||
            data?.teamData?.player?.find(
              (item: any) => item?.playerId === data?.player?.id
            ) ||
            checkPlayerPosition(data?.player?.primaryPosition)
          }
          mode="primary"
          className="rounded-[8px] !h-[40px] !w-[208px] px-2"
        >
          {data?.isAdmin ? "Add by Admin" : "Add for Free"}
        </Button>
      )}
    </div>
  );
};
const useAvailablePlayers = ({
  leagueId,
  team,
  refetchTeam,
  isAdmin,
}: {
  leagueId: string;
  team: any;
  refetchTeam?: () => void;
  isAdmin?: boolean;
}) => {
  const { savedPlayers, setSavedPlayers } = useAuthStore.getState();
  const [limit, setLimit] = useState(50);
  const [isPlayersLoading, setIsPlayersLoading] = useState(false);
  const { allPlayers } = useAuthStore(["allPlayers"]);
  const [activeFilter, setActiveFilter] = useState("All");
  const [playersTypeFilter, setPlayersTypeFilter] = useState("All");
  const { data: playersOnWaiver, refetch: refetchWaiver } =
    useFetchPlayersOnWaiver(team?.id);
  const { data: biddedPlayers } = useFetchMyBids(team?.id);
  const { data: league } = useFetchLeague(leagueId!);
  const [allPlayersForFilter, setAllPlayersForFilters] = useState<any>(
    allPlayers?.players?.slice(0, limit)
  );
  const [searchName, setSearchName] = useState("");
  const { getNextPlayers, isFetching } = useLoadPlayers(leagueId!);
  const { mutateAsync: onSearchPlayer } = useSearchPlayers();
  const { data: eliminationDate } = useFetchEliminationDate();

  useEffect(() => {
    if (allPlayers?.players?.length > limit) {
      setIsPlayersLoading(true);
      setTimeout(() => {
        setAllPlayersForFilters(allPlayers?.players?.slice(0, limit));
        setIsPlayersLoading(false);
      }, 1500);
    } else {
      getNextPlayers();
      setAllPlayersForFilters(allPlayers?.players?.slice(0, limit));
    }
  }, [limit]);

  const loadMore = () => {
    setLimit(limit + 50);
  };

  const handleAllPlayers = () => {
    const responseData = allPlayers?.players ? allPlayers?.players : [];

    const players = responseData?.reduce(
      (accumulator: any, currentPlayer: any) => {
        const waiverPlayer = playersOnWaiver?.find(
          (item: any) =>
            item?.playerId?.toString() === currentPlayer?.player?.id?.toString()
        );
        const biddedPlayer = biddedPlayers?.find(
          (item: any) =>
            item?.playerId === currentPlayer?.player?.id?.toString() &&
            item?.status === "inProgress"
        );
        const saved = savedPlayers?.find(
          (item: any) => item?.id === currentPlayer?.player?.id
        );

        if (waiverPlayer) {
          currentPlayer.waiver = waiverPlayer;
        } else {
          currentPlayer.waiver = false;
        }
        if (biddedPlayer) {
          currentPlayer.bid = biddedPlayer;
        } else {
          currentPlayer.bid = false;
        }
        if (saved) {
          currentPlayer.saved = true;
        } else {
          currentPlayer.saved = false;
        }

        return [...accumulator, currentPlayer];
      },
      []
    );
    const playersData = players ? players : [];
    if (activeFilter === "All") {
      if (playersTypeFilter) {
        handlePlayersType(playersTypeFilter);
      } else {
        setAllPlayersForFilters(playersData);
      }
    } else if (activeFilter === "My bids") {
      const bidsPlayers = playersData?.filter((item: any) => item?.bid);
      setAllPlayersForFilters(bidsPlayers);
    } else if (activeFilter === "Saved") {
      const playersSaved = playersData?.filter((item: any) => item?.saved);
      setAllPlayersForFilters(playersSaved);
    }

    return playersData;
  };

  const handlePlayersType = (type: string) => {
    switch (type) {
      case "QB":
        setPlayersTypeFilter("QB");
        const QBPlayers = allPlayers?.players?.filter(
          (item: any) => item?.player?.primaryPosition === "QB"
        );
        setAllPlayersForFilters(QBPlayers);
        break;
      case "WR":
        setPlayersTypeFilter("WR");
        const WRPlayers = allPlayers?.players?.filter(
          (item: any) => item?.player?.primaryPosition === "WR"
        );
        setAllPlayersForFilters(WRPlayers);
        break;
      case "RB":
        setPlayersTypeFilter("RB");
        const KPlayers = allPlayers?.players?.filter(
          (item: any) => item?.player?.primaryPosition === "RB"
        );
        setAllPlayersForFilters(KPlayers);
        break;
      case "TE":
        setPlayersTypeFilter("TE");
        const DEFPlayers = allPlayers?.players?.filter(
          (item: any) => item?.player?.primaryPosition === "TE"
        );
        setAllPlayersForFilters(DEFPlayers);
        break;
      case "All":
        setPlayersTypeFilter("All");
        setAllPlayersForFilters(allPlayers?.players?.slice(0, limit));
        break;
      default:
        handleAllPlayers();
        break;
    }
  };

  const handleSavedPlayers = (player: any) => {
    const savedPlayer = savedPlayers?.find(
      (item: any) => item?.id === player?.id
    );
    if (savedPlayer) {
      setSavedPlayers(
        savedPlayers?.filter((item: any) => item?.id !== player?.id)
      );
    } else {
      setSavedPlayers([...savedPlayers, player]);
    }
  };

  useEffect(() => {
    handleAllPlayers();
  }, [playersOnWaiver, biddedPlayers, activeFilter]);

  const searchPlayer = async () => {
    if (searchName) {
      try {
        const searchedPlayers = await onSearchPlayer({
          name: searchName,
          leagueId: leagueId!,
        });
        if (searchedPlayers?.length) {
          const players = searchedPlayers?.reduce(
            (accumulator: any, currentPlayer: any) => {
              const waiverPlayer = playersOnWaiver?.find(
                (item: any) =>
                  item?.playerId === currentPlayer?.player?.id?.toString()
              );
              const biddedPlayer = biddedPlayers?.find(
                (item: any) =>
                  item?.playerId === currentPlayer?.player?.id?.toString() &&
                  item?.status === "inProgress"
              );
              const saved = savedPlayers?.find(
                (item: any) => item?.id === currentPlayer?.player?.id
              );
              if (waiverPlayer) {
                currentPlayer.waiver = waiverPlayer;
              } else {
                currentPlayer.waiver = false;
              }
              if (biddedPlayer) {
                currentPlayer.bid = biddedPlayer;
              } else {
                currentPlayer.bid = false;
              }
              if (saved) {
                currentPlayer.saved = true;
              } else {
                currentPlayer.saved = false;
              }

              return [...accumulator, currentPlayer];
            },
            []
          );
          setAllPlayersForFilters(players);
        } else {
          const filteredPlayers = allPlayersForFilter?.filter((item: any) => {
            const name = item?.player?.firstName + " " + item?.player?.lastName;
            const result = name
              ?.toLocaleLowerCase()
              ?.includes(searchName?.toLocaleLowerCase());
            return result;
          });
          setAllPlayersForFilters(filteredPlayers);
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.error);
      }
    } else {
      handlePlayersType(activeFilter);
    }
  };

  const columsPlayers = [
    {
      title: (
        <p className="text-base font-gilroyMedium text-text-grey">
          {activeFilter === "My bids" ? "Bid priority" : "RK"}
        </p>
      ),
      dataIndex: "rk",
      thClassName: "w-[5%]",
      render: (_: string, record: any) => {
        return activeFilter === "My bids" ? (
          <BidPriorityRender
            {...record}
            allBids={biddedPlayers?.filter(
              (item: any) => item?.status === "inProgress"
            )}
            allPlayersForFilter={allPlayersForFilter}
          />
        ) : (
          <RKRender {...record} />
        );
      },
    },
    {
      title: (
        <p className="text-base font-gilroyMedium text-text-grey">Players</p>
      ),
      dataIndex: "SelectedPlayers",
      thClassName: "w-[40%]",
      render: (_: string, record: any) => {
        return (
          <PlayerRender
            leagueId={leagueId}
            {...record}
            eliminationDate={eliminationDate}
            teamId={team?.id}
          />
        );
      },
    },

    {
      title: (
        <p className="text-base font-gilroyMedium text-text-grey">CMP/ATT</p>
      ),
      dataIndex: "passATT",
      thClassName: "w-[8%]",
      render: (_: string, record: any) => {
        return <PassATTRender {...record} />;
      },
    },
    {
      title: <p className="text-base font-gilroyMedium text-text-grey">YDS</p>,
      dataIndex: "passYards",
      thClassName: "w-[10%]",
      render: (_: string, record: any) => {
        return <PassYardsRender {...record} />;
      },
    },
    {
      title: (
        <div className="relative">
          <p className="text-[12px] font-gilroyBold text-text-grey top-[-12px] -translate-x-[100%] absolute">
            Passing
          </p>
          <p className="text-base font-gilroyMedium text-text-grey">TDs</p>
        </div>
      ),
      dataIndex: "passTD",
      thClassName: "w-[10%]",
      render: (_: string, record: any) => {
        return <PassTDsRender {...record} />;
      },
    },
    {
      title: (
        <p className="text-base font-gilroyMedium text-text-grey mr-4">INTs</p>
      ),
      dataIndex: "intrceptions",
      thClassName: "w-[10%]",
      render: (_: string, record: any) => {
        return <InttrRender {...record} />;
      },
    },
    {
      title: <p className="text-base font-gilroyMedium text-text-grey">YDS</p>,
      dataIndex: "rushYards",
      thClassName: "w-[10%]",
      render: (_: string, record: any) => {
        return <RushYardsRender {...record} />;
      },
    },
    {
      title: (
        <div className="relative mr-6">
          <p className="text-[12px] font-gilroyBold text-text-grey top-[-12px] -translate-x-[100%] absolute">
            Rushing
          </p>
          <p className="text-base font-gilroyMedium text-text-grey">TDs</p>
        </div>
      ),
      dataIndex: "rushTD",
      thClassName: "w-[10%]",
      render: (_: string, record: any) => {
        return <RushTDsRender {...record} />;
      },
    },

    {
      title: <p className="text-base font-gilroyMedium text-text-grey">Rec</p>,
      dataIndex: "rec",
      thClassName: "w-[10%]",
      render: (_: string, record: any) => {
        return <RecRender {...record} />;
      },
    },
    {
      title: (
        <div className="relative">
          <p className="text-[12px] font-gilroyBold text-text-grey top-[-12px] -translate-x-[20%] absolute">
            Receiving
          </p>
          <p className="text-base font-gilroyMedium text-text-grey">YDS</p>
        </div>
      ),
      dataIndex: "recYards",
      thClassName: "w-[10%]",
      render: (_: string, record: any) => {
        return <RecYardsRender {...record} />;
      },
    },
    {
      title: <p className="text-base font-gilroyMedium text-text-grey">TDs</p>,
      dataIndex: "recTD",
      thClassName: "w-[10%]",
      render: (_: string, record: any) => {
        return <RecTDsRender {...record} />;
      },
    },
    {
      title: (
        <p className="text-base font-gilroyMedium text-text-grey">Actions</p>
      ),
      dataIndex: "actions",
      render: (_: string, record: any) => {
        return (
          <ActionsRender
            handleSavedPlayers={handleSavedPlayers}
            savedPlayers={savedPlayers}
            teamData={team}
            league={league?.league}
            refetchTeam={refetchTeam}
            refetchWaiver={refetchWaiver}
            isAdmin={isAdmin}
            {...record}
          />
        );
      },
    },
  ];
  return {
    isFetching,
    activeFilter,
    playersTypeFilter,
    allPlayers,
    handlePlayersType,
    columsPlayers,
    setActiveFilter,
    loadMore,
    allPlayersForFilter,
    setAllPlayersForFilters,
    setPlayersTypeFilter,
    setSearchName,
    searchPlayer,
    isPlayersLoading,
  };
};

export default useAvailablePlayers;
