import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import { QueryClientProvider } from "@tanstack/react-query";
import LayoutContainer from "./containers/LayoutContainer";

import AuthLoading from "./AuthLoading";
import queryClient from "./utils/queryClient";
import "./configs/axiosInterceptor";
import { useEffect, useState } from "react";
import { socket } from "./socket";
import ContextProvider from "./context/SessionContext";

function App() {
  const [isConnected, setIsConnected] = useState(socket.connected);

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
      console.log(isConnected, "isConnected");
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    socket.on("connect", onConnect);
    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };
  }, []);

  return (
    <main>
      <ContextProvider>
        <QueryClientProvider client={queryClient}>
          <ToastContainer />
          <LayoutContainer>
            <AuthLoading />
          </LayoutContainer>
        </QueryClientProvider>
      </ContextProvider>
    </main>
  );
}

export default App;
