import { toast } from "react-toastify";
import { UserServices } from "../../services/apis/User/UserServices";
import useAuthStore from "../../stores/auth/auth";

export const AuthSessionServices = {
  createSession: async () => {
    try {
      const { loginData, userData: user } = useAuthStore.getState();
      if (!loginData?.access_token) {
        throw new Error("No login data found");
      }

      if (!window.navigator.onLine) {
        user?.id && useAuthStore.setState({ isLoggedIn: true });
        toast.error("No internet connection");
        return false;
      }
      const userData = await UserServices.fetchUser();

      if (userData?.account_status !== "active" || !userData?.id) {
        throw new Error("User account is not active");
      }
      useAuthStore.setState({
        isLoggedIn: true,
        userData: userData,
      });
    } catch (error) {
      AuthSessionServices.clearSession();
    }
  },
  clearSession: async () => {
    try {
      useAuthStore.setState({
        isLoggedIn: false,
        loginData: null,
        userData: null,
      });
      localStorage.clear();
    } catch (error) {
      console.log("error", error);
    }
  },
};
