import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { PLAYER_QUERY_KEY } from "./playerQueriesKeys";
import { PlayerServices } from "../../services/apis/Player/PlayerServices";
import { TEAMS_QUERY_KEY } from "../teamsQueries/teamsQueriesKeys";
import { WAIVER_QUERY_KEY } from "../../queries/waiverQueries/waiverQueriesKeys";

export const useFetchAvailablePlayers = (id: string, params: any) => {
  const queryResult = useQuery<any>({
    queryKey: [PLAYER_QUERY_KEY.FETCH_PLAYERS],
    queryFn: () => PlayerServices.getAvailablePlayers(id, params),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!id,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};

export const useAddPlayerToTeam = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: PlayerServices.addPlayerToTeam,
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: [
          WAIVER_QUERY_KEY.FETCH_PLAYERS_ON_WAIVER,
          TEAMS_QUERY_KEY.FETCH_TEAM_DETAILS,
          PLAYER_QUERY_KEY.FETCH_TEAM_PLAYERS,
        ],
      });
    },
  });
};

export const useFetchPlayer = (id: string, leagueId: string) => {
  const queryResult = useQuery<any>({
    queryKey: [PLAYER_QUERY_KEY.FETCH_PLAYER, id, leagueId],
    queryFn: () => PlayerServices.getPlayerById(id, leagueId),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!id && !!leagueId,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};
export const useFetchPlayerStats = (firstName: string, lastName: string) => {
  const queryResult = useQuery<any>({
    queryKey: [PLAYER_QUERY_KEY.FETCH_PLAYER_STATS],
    queryFn: () => PlayerServices.getPlayerStatsByName(firstName, lastName),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!firstName && !!lastName,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};
export const useFetchPlayerInjures = (firstName: string, lastName: string) => {
  const queryResult = useQuery<any>({
    queryKey: [PLAYER_QUERY_KEY.FETCH_PLAYER_INJURES],
    queryFn: () => PlayerServices.getPlayerInjuresByName(firstName, lastName),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!firstName && !!lastName,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};
export const useRemovePlayer = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: PlayerServices.removePlayer,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          PLAYER_QUERY_KEY.FETCH_PLAYERS,
          TEAMS_QUERY_KEY.FETCH_TEAM_DETAILS,
          PLAYER_QUERY_KEY.FETCH_TEAM_PLAYERS,
        ],
      });
    },
  });
};

export const useSearchPlayers = () => {
  return useMutation({
    mutationFn: PlayerServices.searchPlayer,
  });
};
export const useFetchPlayerWeeklyStats = (params: any, isPRJ: boolean) => {
  const queryResult = useQuery<any>({
    queryKey: [PLAYER_QUERY_KEY.FETCH_PLAYER_WEEKLY_STATS, params],
    queryFn: () => PlayerServices.getPlayerWeeklyStats(params, isPRJ),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: !!params?.firstName && !!params?.lastName,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};
export const useReplacePlayer = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: PlayerServices.replacePlayer,
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: [
          WAIVER_QUERY_KEY.FETCH_PLAYERS_ON_WAIVER,
          TEAMS_QUERY_KEY.FETCH_TEAM_DETAILS,
          PLAYER_QUERY_KEY.FETCH_TEAM_PLAYERS,
        ],
      });
    },
  });
};

export const useFetchTeamPlayers = (
  payload: any,
  week: number,
  isPRJ: boolean
) => {
  const queryResult = useQuery<any>({
    queryKey: [PLAYER_QUERY_KEY.FETCH_TEAM_PLAYERS, week, payload?.names],
    queryFn: () => PlayerServices.getTeamPlayersByWeek(payload, week, isPRJ),
    refetchOnWindowFocus: false,

    retry: 0,
    // enabled: !!payload?.names?.length && !!week,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};
export const useFetchTeamPlayersPRJ = (
  payload: any,
  week: number,
  isPRJ: boolean,
  // isEnabled: boolean
) => {
  const queryResult = useQuery<any>({
    queryKey: [PLAYER_QUERY_KEY.FETCH_TEAM_PLAYERS_PRJ, week, payload?.names],
    queryFn: () => PlayerServices.getTeamPlayersByWeek(payload, week, isPRJ),
    refetchOnWindowFocus: false,
    retry: 0,
    // enabled: !!payload?.names?.length && !!week && isEnabled,
    placeholderData: keepPreviousData,
  });

  return queryResult;
};
